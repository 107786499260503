import React from 'react';
import Breadcrumb from '../../components/breadcrumb';
import { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import baseUrl from '../../baseUrl';
import { CSVLink, CSVDownload } from "react-csv";
import { capitalize } from "../../functions";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Moment from 'moment';

// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

const ClientsDatabase = () => {

    const [dataNew, setData] = useState([]);
    const [csvData, setCsvData] = useState([]);

    const newCustomToolbar = () => {
        return (<div>
            <CSVLink data={csvData} className="text-secondary text-decoration-none"><span>Descargar CSV</span><FontAwesomeIcon icon={faFileCsv} className="ms-2 fs-6" /></CSVLink>
        </div>)
    }
    const columns = [
        {
            name: "id",
            label: "Nº",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => <span>{tableMeta.rowIndex + 1}</span>,
            }
        },
        {
            name: "pasajero",
            label: "Pasajero",
            options: {
                customBodyRender: value => <span>{capitalize(value)}</span>,
            }
        },
        {
            name: "dni",
            label: "D.N.I",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => <div className="d-flex flex-column"><span>{dataNew[dataIndex].dni}</span><span style={{ fontSize: "smaller" }}>{dataNew[dataIndex].fechavenc ? Moment(dataNew[dataIndex].fechavenc).format('DD/MM/YYYY') : null}</span></div>,
            }
        },
        {
            name: "fechanac",
            label: "Fecha de Nac.",
            options: {
                customBodyRender: value => <span>{Moment(value).format('DD/MM/YYYY')}</span>
            }
        },
        {
            name: "sexo",
            label: "Sexo",
        },
        {
            name: "telefono",
            label: "Telefono",
        },
        {
            name: "email",
            label: "E-mail",
        },
        {
            name: "localidad",
            label: "Localidad",
            options: {
                customBodyRender: value => <span>{capitalize(value)}</span>,
            }
        },
        {
            name: "salud",
            label: "Salud",
        },
        {
            name: "pasaporte",
            label: "Pasaporte",
        },

    ]   
       const options = {
         filterType: 'checkbox',
         rowsPerPage: 30,
         searchPlaceholder: "Buscar...",
         elevation: 0,
         selectableRows: 'none',
         customToolbar: newCustomToolbar,
         textLabels: {
            body: {
                noMatch: "No hay información disponible.",
                toolTip: "Ordenar",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ocultar Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Reiniciar",
            },
            pagination: {
                next: "Página Siguiente",
                previous: "Página Anterior",
                rowsPerPage: "Filas por Página:",
                displayRows: "de",
              },
            viewColumns: {
                title: "Ver Columnas",
                titleAria: "Ver/Ocultar Columnas de la Tabla",
            },
        }
       };

    useEffect(() => {
        getClientsData();
    }, []);

    const getClientsData = async () => {
        Axios.get(baseUrl + "/getDbData").then((response) => {
            setData(response.data);
            createCSV(response.data);
        });
    };

    const createCSV = (data) => {
        const csv = [];
        csv.push(["Name,Given Name,Additional Name,Family Name,Yomi Name,Given Name Yomi,Additional Name Yomi,Family Name Yomi,Name Prefix,Name Suffix,Initials,Nickname,Short Name,Maiden Name,Birthday,Gender,Location,Billing Information,Directory Server,Mileage,Occupation,Hobby,Sensitivity,Priority,Subject,Notes,Language,Photo,Group Membership,E-mail 1 - Type,E-mail 1 - Value,Phone 1 - Type,Phone 1 - Value,Phone 2 - Type,Phone 2 - Value"]);
        data.forEach(contact => {
            if (contact.telefono != "") {
                csv.push([capitalize(contact.pasajero) + "," + capitalize(contact.pasajero) + ",,,,,,,,,,,,,,,,,,,,,,,,,,,* myContacts,,,Mobile," + contact.telefono + ",,"]);
            }
        });
        setCsvData(csv);
    }

    return (
        <div id="Container">
            <Breadcrumb title="Base de Datos" />
            <div id="Table" className="container table-responsive">
                <div id="tableWithData" data-aos="fade-in"> 
                <ThemeProvider theme={createTheme()}>
                            <MUIDataTable
                                data={dataNew}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>

                </div>
            </div>
        </div>
    )
}

export default ClientsDatabase;