import { capitalize } from "../../functions";
import Logo from "../../images/Logo.png";
import ReciboTop from "../../images/recibos-top.png";
import ReciboBottom from "../../images/recibos-bottom.png";

export default function Recibo({ reciboValues, setComponentRef }) {
  return (
    <div
      id="reciboDesign"
      ref={setComponentRef}
      className="px-0 border py-3 fs-6 overflow-hidden"
    >
      <div className="logo row justify-content-end">
        <img src={ReciboTop} alt="top" />
      </div>
      <div className="d-flex flex-column p-3 py-0">
        <div className="logo row justify-content-center">
          <img src={Logo} alt="logo" className="w-30" />
        </div>
        <div className="row my-3 justify-content-between">
          <div className="col d-flex">
            <span className="me-2">Fecha:</span>{" "}
            <div className="border-bottom text-align-start col-4">
              {reciboValues.fecha}
            </div>
          </div>
          <div className="col d-flex justify-content-flex-end">
            <span className="me-2">Destino:</span>{" "}
            <div className="border-bottom text-align-start col">
              {reciboValues.destino}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex">
            <span className="me-2">Pasajero:</span>{" "}
            <div className="fw-bold border-bottom text-align-start col-9">
              {capitalize(reciboValues.pasajero)}
            </div>
          </div>
          <div className="col d-flex">
            <span className="me-2">Medio de Pago:</span>{" "}
            <div className="border-bottom text-align-start col">
              {reciboValues.medioDePago}
            </div>
          </div>
        </div>
        <div className="row my-2">
          <div className="col d-flex flex-column">
            <span className="me-2">Recibi(mos) la suma de:</span>
            <div className="border-bottom text-align-start mt-2">
              {capitalize(reciboValues.priceText)}
            </div>
          </div>
        </div>
        <div className="row my-2">
          <div className="col d-flex">
            <span className="me-2">En concepto de:</span>
            <div className="border-bottom text-align-starts col-7">
              Entrega por {reciboValues.tipo}
            </div>
          </div>
          <div className="col d-flex">
            <span className="me-2">Comentario:</span>
            <div className="border-bottom text-align-starts col">
              {capitalize(reciboValues.comentario)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8 d-flex align-items-start flex-column">
            <span>Total:</span>
            <div className="p-3 bg-primary text-white fs-3 fw-600 text-center">
              {reciboValues.totalPesos !== "" &&
              reciboValues.totalDol !== "" ? (
                <div>
                  ${reciboValues.totalPesos} + U$S {reciboValues.totalDol}
                </div>
              ) : (
                <div>
                  {reciboValues.totalPesos !== ""
                    ? "$" + reciboValues.totalPesos
                    : ""}
                  {reciboValues.totalDol !== ""
                    ? "U$S" + reciboValues.totalDol
                    : ""}
                </div>
              )}
            </div>
          </div>
          <div className="col-4 d-flex flex-column">
            <span className="mt-2 pt-3">Firma</span>
            <span className="mt-3">Aclaracion</span>
          </div>
        </div>
      </div>
      <div className="logo row justify-content-end">
        <img src={ReciboBottom} alt="top" />
      </div>
    </div>
  );
}
