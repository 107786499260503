import Breadcrumb from "../../components/breadcrumb";
import ImgGrillaGeneric from '../../images/g.jpg';
import { useState, useEffect, useRef } from "react";
import React from 'react';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Axios from 'axios';
import GrillaDrag from "../../DnD/grilla-drag";
import GrillaDrop from "../../DnD/grilla-drop";
import { capitalize } from "../../functions";
import baseUrl from "../../baseUrl";
import Moment from 'moment';
import GrillaGeneric from "./../../images/grilla-generic.png";
import ButacaOcupada from "./../../images/butaca-ocupada.png";
import html2pdf from 'html2pdf.js';
import ReactToPrint from "react-to-print";
import EmptyState1 from '../../images/EmptyState1.png';

//EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPrint, faFilePdf } from '@fortawesome/free-solid-svg-icons';


const Grilla = () => {

    const arrayPlaces = [
        { "X": 11.8, "Y": 107 }, //F01A
        { "X": 48.8, "Y": 107 }, //F01B
        { "X": 120.5, "Y": 107 }, //F01C
        { "X": 157.5, "Y": 107 }, //F01D
        { "X": 11.8, "Y": 155.5 }, //F02A
        { "X": 48.8, "Y": 155.5 }, //F02B
        { "X": 11.8, "Y": 203 }, //F03A
        { "X": 48.8, "Y": 203 }, //F03B
        { "X": 11.8, "Y": 251.5 }, //F04A
        { "X": 48.8, "Y": 251.5 }, //F04B
        { "X": 120.5, "Y": 251.5 }, //F04C
        { "X": 157.5, "Y": 251.5 }, //F04D						
        { "X": 11.8, "Y": 300 }, //F05A
        { "X": 48.8, "Y": 300 }, //F05B
        { "X": 120.5, "Y": 300 }, //F05C
        { "X": 157.5, "Y": 300 }, //F05D
        { "X": 11.8, "Y": 349 }, //F06A
        { "X": 48.8, "Y": 349 }, //F06B
        { "X": 120.5, "Y": 349 }, //F06C
        { "X": 157.5, "Y": 349 }, //F06D
        { "X": 11.8, "Y": 398 }, //F07A
        { "X": 48.8, "Y": 398 }, //F07B
        { "X": 120.5, "Y": 398 }, //F07C
        { "X": 157.5, "Y": 398 }, //F07D
        { "X": 11.8, "Y": 446 }, //F08A
        { "X": 48.8, "Y": 446 }, //F08B
        { "X": 120.5, "Y": 446 }, //F08C
        { "X": 157.5, "Y": 446 }, //F08D
        { "X": 11.8, "Y": 495 }, //F09A
        { "X": 48.8, "Y": 495 }, //F09B
        { "X": 120.5, "Y": 495 }, //F09C
        { "X": 157.5, "Y": 495 }, //F09D
        { "X": 11.8, "Y": 544 }, //F10A
        { "X": 48.8, "Y": 544 }, //F10B
        { "X": 120.5, "Y": 544 }, //F10C
        { "X": 157.5, "Y": 544 }, //F10D
        { "X": 11.8, "Y": 592 }, //F11A
        { "X": 48.8, "Y": 592 }, //F11B
        { "X": 120.5, "Y": 592 }, //F11C
        { "X": 157.5, "Y": 592 }, //F11D
        { "X": 11.8, "Y": 641 }, //F12A
        { "X": 48.8, "Y": 641 }, //F12B
        { "X": 120.5, "Y": 641 }, //F12C
        { "X": 157.5, "Y": 641 }, //F12D
        { "X": 11.8, "Y": 689 }, //F13A
        { "X": 48.8, "Y": 689 }, //F13B
        { "X": 120.5, "Y": 689 }, //F13C
        { "X": 157.5, "Y": 689 }, //F13D
        { "X": 11.8, "Y": 804 }, //F14A
        { "X": 48.8, "Y": 804 }, //F14B
        { "X": 157.5, "Y": 804 }, // F14C
        { "X": 11.8, "Y": 852 }, //F15A
        { "X": 48.8, "Y": 852 }, //F15B
        { "X": 157.5, "Y": 852 }, //F15C
        { "X": 11.8, "Y": 901 }, //F16A	
        { "X": 48.8, "Y": 901 }, //F16B
        { "X": 157.5, "Y": 901 }, //F16C
        { "X": 11.8, "Y": 949 }, //F17A
        { "X": 48.8, "Y": 949 }, //F17B
        { "X": 157.5, "Y": 949 }, //F17C
    ];

    const seats = ['F01A', 'F01B', 'F01C', 'F01D', 'F02A', 'F02B', 'F03A', 'F03B', 'F04A', 'F04B', 'F04C', 'F04D', 'F05A', 'F05B', 'F05C', 'F05D', 'F06A', 'F06B', 'F06C', 'F06D'];
    const seats2 = ['F07A', 'F07B', 'F07C', 'F07D', 'F08A', 'F08B', 'F08C', 'F08D', 'F09A', 'F09B', 'F09C', 'F09D', 'F10A', 'F10B', 'F10C', 'F10D', 'F11A', 'F11B', 'F11C', 'F11D'];
    const seats3 = ['F12A', 'F12B', 'F12C', 'F12D', 'F13A', 'F13B', 'F13C', 'F13D', 'F14A', 'F14B', 'F14C', 'F15A', 'F15B', 'F15C', 'F16A', 'F16B', 'F16C', 'F17A', 'F17B', 'F17C'];

    const [passengers, setPassengersData] = useState([]);
    const [passengersFiltered, setPassengersFilteredData] = useState([]);
    const [trips, setTrips] = useState([]);
    const [selectedTrip, setSelectedTrip] = useState();

    let componentRef = useRef(null);

    useEffect(() => {
        getTrips();
    }, [])

    const canvasRef = useRef();

    const takePlace = (nButaca) => {
        const ctx = canvasRef.current.getContext('2d');
        const imgOcupado = new Image();
        imgOcupado.width = 15;
        imgOcupado.height = 15;
        imgOcupado.src = ButacaOcupada;
        imgOcupado.onload = function () {
            ctx.drawImage(imgOcupado, arrayPlaces[nButaca].X, arrayPlaces[nButaca].Y);
        }
    }

    const clearCanvas = () => {
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, 200, 1000);
    }

    const clearCanvasSeat = (seat) => {
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(arrayPlaces[seat].X, arrayPlaces[seat].Y, 33, 31);
    }

    const getTrips = async () => {
        await Axios.get(baseUrl + "/getTripsData").then((response) => {
            setTrips(response.data);
        });
    }

    const getPassengersData = async (e) => {
        setSelectedTrip(trips[e.target.selectedIndex - 1]);
        await Axios.get(baseUrl + "/getPassengersData", { params: { selectedTrip: e.target.value } }).then((response) => {
            clearCanvas();
            setPassengersData(response.data);
            setPassengersFilteredData(response.data.filter((passenger) => passenger.butaca == ""));
        });
    };

    const deleteBusPlace = (p, seat) => {
        Axios.post(baseUrl + "/updateBusPlace", { selectedTrip: selectedTrip.tableId, passengerId: p.id, butaca: "" }).then((response) => {
            console.log("PLACE UPDATED!");
            p["butaca"] = "";
            setPassengersFilteredData([...passengersFiltered, p]);
            clearCanvasSeat(seat);
            setPassengersData([...passengers.filter((passenger) => passenger.id !== p.id), p]);
        });
    }

    const buildPassengerValue = (p, i) => {
        return <div style={{ height: "38px" }} className="form-control bg-danger text-white w-75 d-flex justify-content-between disable-select fw-600-onPrint">
            <div style={{ overflow: "hidden" }}>{capitalize(p.pasajero)}</div>
            <FontAwesomeIcon icon={faTimes} className="text-white fs-5 ms-2 cursor-pointer hideOnPrint"
                onClick={() => deleteBusPlace(p, i)} />
        </div>
    }

    const reactToPrintContent = () => {
        return componentRef;
    };

    const setComponentRef = (ref) => {
        componentRef = ref;
    };

    const reactToPrintTrigger = () => {
        return <button type="button" class="btn btn-primary me-2" scale={0.6}><FontAwesomeIcon icon={faPrint} /></button>;
    };

    const downloadPdf = (fileName) => {
        const element = document.getElementById('grillaPrint').innerHTML;
        const options = {
            image: { type: 'png' },
            filename: fileName + '.pdf',
            jsPDF: { unit: 'in', format: 'a4', orientation: 'l' }
        };
        html2pdf(element, options);
    }

    return (
        <div id="Grilla">
            <Breadcrumb title="Grilla" />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-6 mb-2">
                        <select name="trip" className="form-control" defaultValue={"default"} onChange={getPassengersData}>
                            <option value="default" disabled hidden>Seleccione un destino</option>
                            {trips.map((trip) => (
                                <option value={trip.tableId} >{trip.nombre} ({Moment(trip.fecha).format('DD/MM/YYYY')})</option>
                            ))}
                        </select>
                    </div>
                </div>
                {selectedTrip ?
                    <div>
                        <div className="d-flex justify-content-center mb-5" data-aos="fade-in">
                            <ReactToPrint
                                content={reactToPrintContent}
                                documentTitle="GRILLA"
                                trigger={reactToPrintTrigger}
                            />
                            <button type="button" className="btn btn-primary me-2" onClick={() => downloadPdf("GRILLA - " + selectedTrip.nombre + " " + Moment(selectedTrip.fecha).format('DD-MM-YYYY'))}>
                                <FontAwesomeIcon icon={faFilePdf} />
                            </button>
                        </div>

                        <div data-aos="fade-in" id="grillaPrint" className="row justify-content-center" ref={setComponentRef}>
                            <div className="row justify-content-center col-12 flex-column mb-3 d-none display-block-onPrint">
                                <div className="col bg-primary text-white fs-4 fw-600 text-center mt-3 text-uppercase">
                                    {selectedTrip ? selectedTrip.nombre : null}
                                </div>
                                <div className="col bg-darkblue text-white fs-6 fw-600 text-center text-uppercase">
                                    {selectedTrip ? Moment(selectedTrip.fecha).format('DD/MM/YYYY') : ""}
                                </div>
                            </div>
                            <div id="grilla-canvas" className="col-2">
                                <canvas style={{ background: "url(" + GrillaGeneric + ")" }} width={200} height={1000} ref={canvasRef} />
                            </div>
                            <DndProvider backend={HTML5Backend}>
                                <div className="col-7 d-flex flex-column">
                                    <span className="fs-4 fw-600 text-center mb-4 border-bottom">BUTACAS</span>
                                    <div id="drops" className="w-100 d-flex flex-row">
                                        <div className="col-4">
                                            {seats.map((seat, i) => (
                                                (passengers.filter((passenger, index) => passenger.butaca === seat)).length > 0 ?
                                                    <div className="row">
                                                        {(passengers.filter((passenger, index) => passenger.butaca === seat).map((p) => (
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <span className="w-35px me-15px">{seat}</span>
                                                                {buildPassengerValue(p, i)}
                                                                {takePlace(i)}
                                                            </div>
                                                        )))}
                                                    </div>
                                                    :
                                                    <GrillaDrop deleteBusPlace={deleteBusPlace} selectedTrip={selectedTrip} idButaca={i} busNumber={seat} idField={i} takePlace={takePlace} passengers={passengersFiltered} setPassengers={setPassengersFilteredData} />
                                            ))}
                                        </div>
                                        <div className="col-4">
                                            {seats2.map((seat, i) => (
                                                (passengers.filter((passenger, index) => passenger.butaca === seat)).length > 0 ?
                                                    <div className="row">
                                                        {(passengers.filter((passenger, index) => passenger.butaca === seat).map((p) => (
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <span className="w-35px me-15px">{seat}</span>
                                                                {buildPassengerValue(p, i)}
                                                                {takePlace(i + 20)}
                                                            </div>
                                                        )))}
                                                    </div>
                                                    :
                                                    <GrillaDrop deleteBusPlace={deleteBusPlace} selectedTrip={selectedTrip} idButaca={seat} idField={i + 20} busNumber={seat} takePlace={takePlace} passengers={passengersFiltered} setPassengers={setPassengersFilteredData} />
                                            ))}
                                        </div>
                                        <div className="col-4">
                                            {seats3.map((seat, i) => (
                                                (passengers.filter((passenger, index) => passenger.butaca === seat)).length > 0 ?
                                                    <div className="row">
                                                        {(passengers.filter((passenger, index) => passenger.butaca === seat).map((p) => (
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <span className="w-35px me-15px">{seat}</span>
                                                                {buildPassengerValue(p, i)}
                                                                {takePlace(i + 40)}
                                                            </div>
                                                        )))}
                                                    </div>
                                                    :
                                                    <GrillaDrop deleteBusPlace={deleteBusPlace} selectedTrip={selectedTrip} idButaca={seat} idField={i + 40} busNumber={seat} takePlace={takePlace} passengers={passengersFiltered} setPassengers={setPassengersFilteredData} />
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-2" id="noSeatsPassengers">
                                    <div className="fs-4 fw-600 text-center mb-4 border-bottom">SIN UBICAR</div>
                                    {passengersFiltered.map((passenger, index) => (
                                        <GrillaDrag key={passenger.id} id={passenger.id} value={capitalize(passenger.pasajero)} passenger={passenger} />
                                    ))}
                                </div>
                            </DndProvider>
                        </div>

                    </div>

                    :
                    <div className="d-flex flex-column justify-content-center align-items-center my-5" data-aos="fade-in">
                        <img src={EmptyState1} alt="Empty" />
                        <span className="fs-4 text-dark opacity-25 mt-2">No hay información disponible</span>
                        <span className="text-light text-dark opacity-25 fs-6">Debes seleccionar un destino</span>
                    </div>
                }
            </div>

        </div>
    )
}

export default Grilla;