import { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, where, query, updateDoc, doc } from "firebase/firestore";


const EditUser = () => {

    const initialStateValues = {
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        pass: "",
        rol: "",
    };

    const [usersArray, setUsersArray] = useState([]);
    const [selectedId, setSelectedId] = useState(0);
    const [selectedDocId, setSelectedDocId] = useState("");
    const [userValues, setUserValues] = useState(initialStateValues);
    const [errorDisplay, setErrorDisplayValue] = useState(false);

    useEffect(() => {
        getUsers();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserValues({ ...userValues, [name]: value });
    };

    const handleValidation = () => {
        return true;
    }

    const handleSubmit = (e) => {
        if (handleValidation() == true) {
            e.preventDefault();
            editUser(userValues);
        } else {
            e.preventDefault();
            setErrorDisplayValue(true);
        }
    }

    const editUser = async (userData) => {
        const userRef = doc(db, "users", selectedDocId);
        await updateDoc(userRef, userData);
    }

    const getUsers = async () => {
        const querySnapshot = await getDocs(collection(db, "users"));
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), docId: doc.id, id: doc.data().id, nombre: doc.data().nombre, apellido: doc.data().apellido });
        });
        setUsersArray(docs);
    }

    const handleSelectChange = async (e) => {
        setSelectedId(e.target.value);

        const q = query(collection(db, "users"), where("id", "==", Number(e.target.value)));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setSelectedDocId(doc.id);
            const newValues = {
                nombre: doc.data().nombre,
                apellido: doc.data().apellido,
                email: doc.data().email,
                telefono: doc.data().telefono,
                pass: doc.data().pass,
                rol: doc.data().rol,
            };
            setUserValues(newValues);
        });
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className="card card-body" id="editUserForm">
                <h4 className="pb-4 mb-4 border-bottom">EDITAR USUARIO</h4>
                <div id="select-and-id">
                    <select class="form-select mb-3" placeholder="Seleccioná un Usuario" name="selectedUser" onChange={handleSelectChange}>
                        {usersArray.map((user) => (
                            <option value={user.id}>{user.nombre} {user.apellido}</option>
                        ))}
                    </select>

                    <div className="mb-3">
                        <span>ID: </span>
                        <span name="userId" id="userId">{selectedId}</span>
                    </div>
                </div>

                <div className="mb-3">
                    <input type="text" name="nombre" value={userValues.nombre} className="form-control" placeholder="Nombre" onChange={handleInputChange} />
                </div>

                <div className="mb-3">
                    <input type="text" name="apellido" value={userValues.apellido} className="form-control" placeholder="Apellido" onChange={handleInputChange} />
                </div>

                <div className="mb-3">
                    <input type="text" name="email" value={userValues.email} className="form-control" placeholder="E-Mail" onChange={handleInputChange} />
                </div>

                <div className="mb-3">
                    <input type="text" name="telefono" value={userValues.telefono} className="form-control" placeholder="Teléfono" onChange={handleInputChange} />
                </div>

                <div className="mb-3">
                    <input type="text" name="pass" value={userValues.pass} className="form-control" placeholder="Contraseña" onChange={handleInputChange} />
                </div>

                <div className="mb-3 d-flex flex-column">
                    <span className="mb-2">Rol:</span>
                    <div className="options">
                        <div class="form-check form-check-inline">
                            <input name="rol" class="form-check-input" type="radio" id="editRadio1" value="Administrador" checked={userValues.rol == "Administrador"} onChange={handleInputChange} />
                            <label class="form-check-label" for="inlineRadio1">Administrador</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input name="rol" class="form-check-input" type="radio" id="editRadio2" value="Secretario" checked={userValues.rol == "Secretario"} onChange={handleInputChange} />
                            <label class="form-check-label" for="inlineRadio2">Secretario</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input name="rol" class="form-check-input" type="radio" id="editRadio3" value="Vendedor" checked={userValues.rol == "Vendedor"} onChange={handleInputChange} />
                            <label class="form-check-label" for="inlineRadio3">Vendedor</label>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" type="submit">GUARDAR</button>
                </div>

                <div className={errorDisplay ? "mt-3 mb-1" : "d-none"}>
                    <span className="text-danger fs-8">* Completa todos los campos obligatorios para continuar.</span>
                </div>

            </form>

        </div>

    )
}

export default EditUser;