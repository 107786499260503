import Modal from 'react-bootstrap/Modal';
import { useEffect, useState, useRef } from 'react';
import { nanoid } from 'nanoid';
import Axios from 'axios';
import baseUrl from "../../baseUrl";
import { toast } from 'react-toastify';

// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const NewTripModal = (props) => {

    const initialNewTripValues = {
        nombre: "",
        fecha: "",
        exterior: 0,
        transporte: "",
        descripcion: "",
        precio: "",
        imgpath: "",
        estadia: "",
        comprar: "",
        archivado: 0,
        dias: "",
        noches: "",
        regimen: "",
        paxMax: 60,
        excursionsId: "",
        tableId: "",
        slidepath: "",
    };

    const [newTripData, setNewTripData] = useState(initialNewTripValues);

    const handleClose = () => {
        props.setNewTripModalIsOpen(false);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTripData({ ...newTripData, [name]: value, tableId: newTripData.nombre + "_" + nanoid(5), excursionsId: "exc_"+newTripData.nombre + "_" + nanoid(5) });
    }

    const handleSubmit = () => {
        Axios.post(baseUrl + "/addNewTrip", { tripData: newTripData });
        props.getTripsData();
        toast.success("¡Exito! Destino agregado");
        props.setNewTripModalIsOpen(false);
    }

    return (
        <Modal show={props.newTripModalIsOpen} backdrop="static" onHide={handleClose} dialogClassName="w-75 mw-inherit">
            <Modal.Header closeButton>
                AGREGAR NUEVO DESTINO
            </Modal.Header>
            <Modal.Body>
                <div className="NewTripModal">
                    <div class="input-group mb-3">
                        <input className="form-control" type="text" name="nombre" placeholder="Destino" onChange={handleInputChange} />
                    </div>

                    <div class="input-group mb-3">
                        <input type="date" className="form-control me-3" name="fecha" placeholder="Fecha de Salida" onChange={handleInputChange} />
                        <input type="number" className="form-control" name="paxMax" placeholder="Cantidad de Pax" onChange={handleInputChange} />
                    </div>

                    <div class="input-group mb-3">
                        <input type="number" className="form-control me-3" name="dias" placeholder="Dias" onChange={handleInputChange} />
                        <input type="number" className="form-control" name="noches" placeholder="Noches" onChange={handleInputChange} />
                    </div>

                    <div class="input-group mb-3">
                        <select type="text" className="form-control me-3" defaultValue="bus" name="transporte" placeholder="" onChange={handleInputChange} >
                            <option value="bus" disabled hidden>Medio de Transporte</option>
                            <option value="bus">Bus</option>
                            <option value="avion">Avion</option>
                        </select>

                        <select className="form-control me-3" name="exterior" defaultValue={0} onChange={handleInputChange}>
                            <option value={0} disabled hidden>Exterior</option>
                            <option value={1}>Si</option>
                            <option value={0}>No</option>
                        </select>

                        <select className="form-control" name="estadia" defaultValue={0} onChange={handleInputChange}>
                            <option value={0} disabled hidden>¿Incluye estadia?</option>
                            <option value={1}>Si</option>
                            <option value={0}>No</option>
                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <select className="form-control" name="regimen" defaultValue="Media Pension" onChange={handleInputChange} >
                            <option value="Media Pension" disabled hidden>Regimen</option>
                            <option value="Desayuno">Desayuno</option>
                            <option value="Media Pension">Media Pension</option>
                            <option value="Pension Completa">Pension Completa</option>
                        </select>
                    </div>

                    <div class="input-group mb-3">
                        <input type="number" className="form-control" name="precio" placeholder="Precio" onChange={handleInputChange} />
                    </div>

                    <div class="input-group mb-3">
                        <textarea type="text" className="form-control" name="descripcion" placeholder="Descripcion" onChange={handleInputChange} />
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" class="btn btn-light" onClick={handleClose}>Cerrar</button>
                <button type="button" class="btn btn-primary" onClick={handleSubmit}>Guardar</button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewTripModal;
