import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion'
import NuevoReciboModal from './admin/recibo-modal';
import CajaModal from './admin/caja-modal';
import { useState } from 'react';
import noProfilePicture from "../images/NoProfilePicture.png";

// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ isOpen, setIsOpen, firebaseUser }) => {

    const [nuevoReciboIsOpen, setNuevoReciboIsOpen] = useState(false);
    const [cajaModalIsOpen, setCajaModalIsOpen] = useState(false);
    const [reciboIsParticular, setReciboIsParticular] = useState(false);

    const handleClose = () => {
        setIsOpen(false)
    };

    const handleNewReciboOpen = () => {
        setNuevoReciboIsOpen(true);
    };

    const handleCajaDelDiaOpen = () => {
        setCajaModalIsOpen(true);
    };

    const closeReciboDropDown = () => {

    };

    return (
        <nav id="navbar" className={isOpen ? 'sidenav active d-flex flex-column justify-content-between' : 'sidenav  d-flex flex-column justify-content-between'}>
            <div>
                <div className="dismiss">
                    <i className="fas fa-arrow-left"></i>
                </div>

                <div className="title d-flex">
                    <h5 className="fw-bold">Panel de Administración</h5>
                    <div className="close-icon mx-2" onClick={() => setIsOpen(!isOpen)}>
                        <i className="box-icon icon-size-30 fs-6 d-flex justify-content-center align-items-center">
                            <span><FontAwesomeIcon icon={faTimes} /></span>
                        </i>
                    </div>
                </div>
                {firebaseUser !== null ?
                    <div className="user d-flex border-bottom mt-3 pb-3">
                        <div className="profile-photo">
                            <img src={noProfilePicture} className="img-fluid rounded-circle img-thumbnail img-80" />
                        </div>
                        <div className="info-profile d-flex flex-column text-align-start ms-2 justify-content-center">
                            <span className="text-end">{firebaseUser.displayName}</span>
                            <span className="text-end fs-8">{firebaseUser.photoURL}</span>
                            <span className="text-end fs-8">{firebaseUser.phoneNumber}</span>
                        </div>
                    </div>
                    : null
                }

                <ul className="list-unstyled menu-elements fs-5 mt-4">
                    <Accordion flush>
                        {firebaseUser !== null && firebaseUser.photoURL == "Administrador" || "Secretario" ?
                            (

                                <div>
                                    <li>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <Link className="scroll-link fw-500" to="#" onClick={handleClose}><i className="fas fa-envelope"></i> Estadísticas</Link>
                                            </Accordion.Header>
                                        </Accordion.Item>
                                    </li>
                                    <li>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                <Link className="scroll-link fw-500" to="/base-de-datos" onClick={handleClose}><i className="fas fa-pencil-alt"></i> Base de Datos</Link>
                                            </Accordion.Header>
                                        </Accordion.Item>
                                    </li>
                                    <li>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                <span className="dropdown-toggle sidebar-dropdown-p fw-500">
                                                    Recibos
                                                </span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Link className="scroll-link" to="#" onClick={() => { handleClose(); handleNewReciboOpen(); setReciboIsParticular(false); }}>Nuevo</Link>
                                            </Accordion.Body>
                                            <Accordion.Body>
                                                <Link className="scroll-link" to="#" onClick={() => { handleClose(); handleNewReciboOpen(); setReciboIsParticular(true); }}>Particular</Link>
                                            </Accordion.Body>
                                            <Accordion.Body>
                                                <Link className="scroll-link" to="/consulta-recibos" onClick={handleClose}>Consulta</Link>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </li>
                                </div>
                            ) : null
                        }
                        <li>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    <span className="dropdown-toggle sidebar-dropdown-p fw-500">
                                        Viajes
                                    </span>
                                </Accordion.Header>
                                {firebaseUser !== null && firebaseUser.photoURL == "Administrador" ||  "Secretario" ? (
                                    <div>
                                        <Accordion.Body>
                                            <Link className="scroll-link" to="/administrar-viajes" onClick={handleClose}>Administrar</Link>
                                        </Accordion.Body>
                                    </div>
                                ) : null
                                }
                                <Accordion.Body>
                                    <Link className="scroll-link" to="/pax" onClick={handleClose}>Pax</Link>
                                </Accordion.Body>
                                <Accordion.Body>
                                    <Link className="scroll-link" to="/grilla" onClick={handleClose}>Grilla</Link>
                                </Accordion.Body>
                                <Accordion.Body>
                                    <Link className="scroll-link" to="/rooming" onClick={handleClose}>Rooming</Link>
                                </Accordion.Body>
                                <Accordion.Body>
                                    <Link className="scroll-link" to="/excursiones" onClick={handleClose}>Excursiones</Link>
                                </Accordion.Body>

                                {firebaseUser !== null && firebaseUser.photoURL == "Administrador" || "Secretario" ? (
                                    <div>
                                        <Accordion.Body>
                                            <Link className="scroll-link" to="#" onClick={handleClose}>Manifiesto</Link>
                                        </Accordion.Body>
                                    </div>
                                ) : null
                                }
                            </Accordion.Item>

                        </li>
                        {firebaseUser !== null && firebaseUser.photoURL == "Administrador" || "Secretario" ? (
                            <div>

                                <li>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>
                                            <Link className="scroll-link fw-500" to="#" onClick={handleClose}><i className="fas fa-cog"></i> Carousel</Link>
                                        </Accordion.Header>
                                    </Accordion.Item>

                                </li>
                                <li>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>
                                            <Link className="scroll-link fw-500" to="/usuarios" onClick={handleClose}><i className="fas fa-user"></i> Usuarios</Link>
                                        </Accordion.Header>
                                    </Accordion.Item>
                                </li>
                            </div>
                        ) : null
                        }

                    </Accordion>
                </ul>
            </div>

            <div>
                <button className="btn btn-primary w-100 fs-5 fw-600" type="button" onClick={() => { handleClose(); handleCajaDelDiaOpen(); }}>CAJA DEL DÍA</button>
            </div>

            <NuevoReciboModal nuevoReciboIsOpen={nuevoReciboIsOpen} setNuevoReciboIsOpen={setNuevoReciboIsOpen} reciboIsParticular={reciboIsParticular} firebaseUser={firebaseUser} />

            <CajaModal cajaModalIsOpen={cajaModalIsOpen} setCajaModalIsOpen={setCajaModalIsOpen} />

        </nav>
    )
}

export default Navbar; 