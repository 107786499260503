// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcDskVrKx_L7jfBNIkLUiXU6s5oUkyWC8",
  authDomain: "taiyo-web-react.firebaseapp.com",
  projectId: "taiyo-web-react",
  storageBucket: "taiyo-web-react.appspot.com",
  messagingSenderId: "777199940031",
  appId: "1:777199940031:web:b27472d80c44560a17710a",
  measurementId: "G-R2J3YF635S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const initializeAuthentication = () =>{
  initializeApp(firebaseConfig)
}

export const db = getFirestore();
export default initializeAuthentication;