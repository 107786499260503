import Breadcrumb from "../components/breadcrumb";

// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { faInstagra, faFacebookF } from '@fortawesome/free-brands-svg-icons';

const Contacto = () => {
    return (
        <div id="Contacto">
            <Breadcrumb title="Contacto" />
            <div className="container">
                <div class="row pb-5">

                    <div className="col-lg-4">
                        <div className="contact-box">
                            <div className="contact-box-icon mb-4 d-flex justify-content-center align-items-center">
                                <span><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
                            </div>
                            <div className="contact-box-info">
                                <h5>Ubicación</h5>
                                <p>Vicente Lopez y Planes 33 Bis</p>
                                <p>Junín, Buenos Aires, 6000</p>
                                <p>Argentina</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="contact-box">
                            <div className="contact-box-icon mb-4 d-flex justify-content-center align-items-center">
                                <span><FontAwesomeIcon icon={faPhoneAlt} /></span>
                            </div>
                            <div className="contact-box-info">
                                <h5>Contacto</h5>
                                <p>Teléfono: (236) 4 435143</p>
                                <p>Celular: (236) 15 4 262872</p>
                                <p>Email: taiyoviajes@gmail.com</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="contact-box">
                            <div className="contact-box-icon mb-4 d-flex justify-content-center align-items-center">
                                <span><FontAwesomeIcon icon={faUser} /></span>
                            </div>
                            <div className="contact-box-info">
                                <h5>Seguinos en</h5>
                                <p>Vicente Lopez y Planes 33 Bis</p>
                                <p>Junín, Buenos Aires, 6000</p>
                                <p>Argentina</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row pb-5">
                    <div className="col-lg-12">
                        <div className="contact-form">
                            <div className="section-title style1 text-center mb-5">
                                <span>¡Deja tú mensaje!</span>
                                <h2>Contactanos</h2>
                            </div>
                            <form className="form-wrap">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input id="nombre" className="form-control" name="name" type="text" placeholder="Apellido y Nombre"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input id="email" className="form-control" name="email" type="text" placeholder="E-Mail"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input id="phone" className="form-control" name="phone" type="text" placeholder="Teléfono"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input id="city" className="form-control" name="city" type="text" placeholder="Ciudad"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group v1">
                                            <textarea name="comment" className="form-control" placeholder="Mensaje" />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <button type="submit" class="btn btn-primary btn-contact">Enviar Mensaje</button>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contacto;