import React, { Component } from 'react';

// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class BoxWrap extends Component{
    render(){
        return(
            <div id="box-wrap" className="col-lg-3 col-md-6 col-sm-6" data-aos="flip-up">
                <div className="box-item style1 mb-30 mt-3">
                    <div className="box-content">
                        <i className="box-icon icon-size-60 d-flex justify-content-center align-items-center">
                            <span><FontAwesomeIcon icon={this.props.icon} /></span>
                        </i>
                        <h4 className="fw-bold"> {this.props.title} </h4>
                        <p>{this.props.description}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default BoxWrap;
