import HomeCarousel from '../components/home-carousel';
import BoxesWrap from '../components/boxes-wrap';
import OurTrips from '../components/our-trips';
import Newsletter from '../components/newsletter';
import DestinosBox from '../components/destinos-box';

const Home = () => {
    return (
        <div className="Home">
            <HomeCarousel />
            <OurTrips />
            <DestinosBox />
            <Newsletter />
        </div>
    )
}

export default Home;