import React, { Component } from 'react';
import BoxWrap from './box-wrap';

// EXTRAS
import { faStar, faHeartbeat } from '@fortawesome/free-solid-svg-icons';

class BoxesWrap extends Component{
    render(){
        return(
            <div id="boxes-wrap" className="pb-4 pt-5">
                <div className="container">
                    <div className="row">
                        <BoxWrap title="Estadía de Primera" icon={faStar} description="Lorempipimpumpan dolor isiti iemam bebasj en snejcje djencv en ne cnenv tg nenc ensbf."/>
                        <BoxWrap title="Seguro del Pasajero" icon={faHeartbeat}  description="Lorem ipsum dolor sit amet, consetetur sadip scing elitr, sed diam nonumy eirmod."  />
                        <BoxWrap title="Estadía de Primera" icon={faHeartbeat}  description="Lorem ipsum dolor sit amet, consetetur sadip scing elitr, sed diam nonumy eirmod." />
                        <BoxWrap title="Estadía de Primera" icon={faHeartbeat}  description="Lorem ipsum dolor sit amet, consetetur sadip scing elitr, sed diam nonumy eirmod." />
                    </div>
                </div>
            </div>
        )
    }
}

export default BoxesWrap;
