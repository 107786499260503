import { useEffect, useState } from "react";
import Moment from "moment";
import baseUrl from "../baseUrl";
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const DestinosBox = () => {

    const [trip, setTrip] = useState();

    useEffect(() => {
        getTrips();
    }, []);

    const getTrips = () => {
        Axios.get(baseUrl + '/getTripsData').then((response) => {
            setTrip(response.data[Math.floor(Math.random() * (response.data).length)]);
        })
    }

    return (
        <div className="my-5">
            {trip ?
                <div className="p-5 outstanding-trip" style={{ backgroundImage: `linear-gradient(0deg, rgb(211, 148, 73, 1) 0%, rgb(157, 31, 61, 1) 100%), url("${trip.imgpath}")` }}>
                    <div className="outstanding-trip-blend"> </div>
                    <div className="container-small p-5 position-relative">
                        <div className="home-destinos-title text-center fs-150rem text-white mb-5 text-shadow-1">
                            Destino Destacado
                        </div>
                        <div className="lh-1 text-center text-white">
                            <div className="fs-3rem text-uppercase text-shadow-1 mb-3">{trip.dias} dias - {trip.noches} noches</div>
                            <div className="fs-5rem text-uppercase fw-900 text-shadow-1 mb-3">{trip.nombre}</div>
                            <div className="fs-7 fw-600 carousel-text fw-500 text-shadow-1 lh-150">{trip.regimen}</div>
                        </div>

                        <div className="d-flex sm-flex-column flex-row mt-5 w-75" style={{margin: "0 auto"}}>
                            
                            <div className="d-flex flex-column justify-content-end bg-darkGray p-3 lh-1">
                                <span className="text-white fs-5 mb-2">VALOR</span>
                                <span className="text-orange fs-2rem fw-800">${trip.precio}</span>
                            </div>

                            <div className="bg-white-90 px-4 py-3 d-flex flex-column">
                                <span className="fs-4 text-orange fw-600">{trip.nombre}</span>
                                <span className="my-3 fs-6 fw-500">{(trip.descripcion).substr(0,125)}...</span>
                                <span className="fw-700 cursor-pointer"><FontAwesomeIcon icon={faCaretRight} className="text-orange me-2" />VER MÁS</span>
                                
                            </div>
                        </div>

                    </div>
                </div>
                :
                <div>
                    
                </div>
            }
        </div>
    )
}

export default DestinosBox;