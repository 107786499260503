import { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./constants";
import { capitalize } from "../functions";
import Axios from "axios";
import baseUrl from "../baseUrl";

//EXTRAS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const GrillaDrop = (props) => {
  const [inputValue, setInputValue] = useState();

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.PASAJERO,
      drop: (monitor) => handleDrop(monitor.value),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  const handleDrop = (data) => {
    props.takePlace(props.idField);
    props.setPassengers(
      props.passengers.filter((passenger) => data.id !== passenger.id)
    );
    setInputValue(data);
    updateBusPlaceInDb(data.id);
  };

  const updateBusPlaceInDb = (idPassenger) => {
    Axios.post(baseUrl + "/updateBusPlace", {
      selectedTrip: props.selectedTrip.tableId,
      passengerId: idPassenger,
      butaca: props.busNumber,
    }).then((response) => {
      console.log("PLACE UPDATED!");
    });
  };

  return (
    <div
      id="drop-zone"
      ref={drop}
      className="d-flex flex-row align-items-center"
    >
      <span className="me-15px w-35px">{props.busNumber}</span>
      <div className="col-9">
        {inputValue ? (
          <div
            style={{ height: "38px" }}
            className="form-control bg-danger text-white mb-2 d-flex justify-content-between disable-select fw-600-onPrint"
          >
            <div style={{ overflow: "hidden" }}>
              {capitalize(inputValue.pasajero)}
            </div>
            <FontAwesomeIcon
              icon={faTimes}
              className="text-white fs-5 ms-2 cursor-pointer hideOnPrint"
              onClick={() => {
                props.deleteBusPlace(inputValue, props.idButaca);
                setInputValue();
              }}
            />
          </div>
        ) : (
          <input value="" disabled className="form-control mb-2" />
        )}
      </div>
    </div>
  );
};

export default GrillaDrop;
