import Modal from "react-bootstrap/Modal";
import { useEffect, useState, useRef } from "react";
import Moment from "moment";
import Axios from "axios";
import { capitalize } from "../../functions";
import Logo from "../../images/Logo.png";
import ReactToPrint from "react-to-print";
import baseUrl from "../../baseUrl";

// EXTRAS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const CajaModal = ({ cajaModalIsOpen, setCajaModalIsOpen }) => {
  const [recibos, setRecibos] = useState([]);
  const todayDate2 = Moment(new Date()).format("DD-MM-YYYY");
  const [totalP, setTotalPesos] = useState(0);
  const [totalD, setTotalDol] = useState();
  const [totalE, setTotalExc] = useState();

  let componentRef = useRef(null);

  useEffect(() => {
    getRecibosData();
  }, [cajaModalIsOpen]);

  const getRecibosData = async () => {
    const todayDate = Moment(new Date()).format("YYYY-MM-DD");
    await Axios.get(baseUrl + "/getRecibosData").then((response) => {
      const filtered = response.data?.filter(
        (recibo) => recibo.fecha === todayDate
      );
      setRecibos(filtered);
      setTotalRecibos(filtered);
    });
  };

  const handleClose = () => {
    setCajaModalIsOpen(false);
  };

  const reactToPrintContent = () => {
    return componentRef;
  };

  const setComponentRef = (ref) => {
    componentRef = ref;
  };

  const reactToPrintTrigger = () => {
    return (
      <button type="button" class="btn btn-primary">
        Imprimir
      </button>
    );
  };

  const setTotalRecibos = (r) => {
    const totalPesos = r.reduce(
      (total, recibo) =>
        recibo.tipo != "Excursiones"
          ? parseInt(total) + parseInt(recibo.totalPesos)
          : total,
      0
    );
    const totalExc = r.reduce(
      (total, recibo) =>
        recibo.tipo == "Excursiones"
          ? parseInt(total) + parseInt(recibo.totalPesos)
          : total,
      0
    );
    setTotalPesos(totalPesos);
    setTotalExc(totalExc);
    setTotalDol(r.reduce((total, recibo) => total + recibo.totalDol, 0));
  };

  const evaluateExcRecibo = (r) => {
    if (r.tipo == "Excursiones") {
      return r.totalPesos;
    } else {
      return 0;
    }
  };

  const evaluatePesosRecibo = (r) => {
    if (r.tipo != "Excursiones") {
      return r.totalPesos;
    } else {
      return 0;
    }
  };

  return (
    <Modal
      show={cajaModalIsOpen}
      backdrop="static"
      onHide={handleClose}
      dialogClassName="w-75 mw-inherit"
    >
      <Modal.Header closeButton>CAJA DEL DÍA</Modal.Header>
      <Modal.Body>
        <div id="CajaDelDia" ref={setComponentRef}>
          <div className="row justify-content-center my-2" id="logo">
            <img src={Logo} alt="logo" className="w-30" />
          </div>
          <div className="row justify-content-center py-2 my-3 fs-4 fw-bold bg-primary text-white">
            CAJA {todayDate2}
          </div>
          <div className="row fw-bold my-3 py-2 border justify-content-center">
            <div className="col-1 border-end">Id</div>
            <div className="col-3 border-end">Pasajero</div>
            <div className="col-2 border-end">Destino</div>
            <div className="col-1 border-end">($)</div>
            <div className="col-1 border-end">U$S</div>
            <div className="col-1 border-end">Exc.</div>
            <div className="col-2">Medio de Pago</div>
          </div>
          {recibos.map((recibo) => (
            <div id="rowCajaDelDia">
              <div className="row border-bottom my-1 py-2 justify-content-center">
                <div className="col-1">0000{recibo.id}</div>
                <div className="col-3">{capitalize(recibo.pasajero)}</div>
                <div className="col-2">{recibo.destino}</div>
                <div className="col-1">{evaluatePesosRecibo(recibo)}</div>
                <div className="col-1">
                  {recibo.totalDol != "" ? recibo.totalDol : "0"}
                </div>
                <div className="col-1">{evaluateExcRecibo(recibo)}</div>
                <div className="col-2">{recibo.medioDePago}</div>
              </div>
            </div>
          ))}

          <div className="row my-2 py-2 flex-row justify-content-center">
            <div className="text-center fs-5">
              <span className="fw-bold">Total Pesos: </span>
              <span className="text-danger fw-bold">${totalP}</span> |
              <span className="fw-bold fw-bold"> Total U$S: </span>
              <span className="text-danger  fw-bold">{totalD}</span> |
              <span className="fw-bold"> Total Exc.: </span>
              <span className="text-danger fw-bold">${totalE}</span>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-light" onClick={handleClose}>
          Cerrar
        </button>
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle="Caja del Dia"
          trigger={reactToPrintTrigger}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CajaModal;
