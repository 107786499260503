import React from "react";
import Breadcrumb from "../../components/breadcrumb";
import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import ModalAdd from "../../components/admin/add-passenger-modal";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Moment from "moment";
import { toast } from "react-toastify";
import { capitalize } from "../../functions";
import ReactToPrint from "react-to-print";
import logo from "../../images/Logo.png";
import baseUrl from "../../baseUrl";
import EditPassenger from "../../components/admin/edit-passenger-modal.js";
import html2pdf from "html2pdf.js";

import EmptyState1 from "../../images/EmptyState1.png";

// EXTRAS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEdit,
  faPlus,
  faFileExcel,
  faPrint,
  faVenusMars,
  faFilePdf,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const Pax = ({ firebaseUser }) => {
  const [trips, setTrips] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [recibos, setRecibos] = useState([]);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [totalPassengers, setTotalPassengers] = useState(0);
  const [maxPassengers, setMaxPassengers] = useState(60);
  const [tripDate, setTripDate] = useState();
  const [selectedTrip, setSelectedTrip] = useState();
  const [editPassengerIsOpen, setEditPassengerIsOpen] = useState(false);
  const [passengerToEdit, setPassengerToEdit] = useState(false);
  const [toPdf, setToPdf] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "id",
      label: "Nº",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <span>{tableMeta.rowIndex + 1}</span>
        ),
      },
    },
    {
      name: "pasajero",
      label: "Pasajero",
      options: {
        customBodyRender: (value) => <span>{capitalize(value)}</span>,
      },
    },
    {
      name: "dni",
      label: "D.N.I",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <div className="d-flex flex-column">
            <span>{passengers[rowIndex].dni}</span>
            <span style={{ fontSize: "smaller" }}>
              {passengers[rowIndex].fechavenc
                ? Moment(passengers[rowIndex].fechavenc).format("DD/MM/YYYY")
                : null}
            </span>
          </div>
        ),
      },
    },
    {
      name: "fechanac",
      label: "Fecha de Nac.",
      options: {
        customBodyRender: (value) => (
          <span>{Moment(value).format("DD/MM/YYYY")}</span>
        ),
      },
    },
    {
      name: "sexo",
      label: "Sexo",
    },
    {
      name: "telefono",
      label: "Telefono",
    },
    {
      name: "localidad",
      label: "Localidad",
      options: {
        customBodyRender: (value) => <span>{capitalize(value)}</span>,
      },
    },
    {
      name: "salud",
      label: "Salud",
    },
    {
      name: "paymentsId",
      label: "PaymentsID",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "totalPesos",
      label: "($)",
    },
    {
      name: "totalDol",
      label: "(U$S)",
    },
    {
      name: "totalExc",
      label: "Exc.",
    },
    {
      name: "vendedora",
      label: "Vend.",
    },
    {
      name: "comentario",
      label: "Comentario",
      options: {
        display: "excluded",
        filter: false,
        customBodyRender: (value) => <span>{capitalize(value)}</span>,
      },
    },
    {
      name: "id",
      label: <FontAwesomeIcon icon={faEdit} />,
      options: {
        customBodyRender: (value) => (
          <span
            className="text-primary cursor-pointer"
            id={value}
            onClick={() => editPassengerOpen(value)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </span>
        ),
      },
    },
    {
      name: "id",
      label: <FontAwesomeIcon icon={faTimes} />,
      options: {
        customBodyRender: (value) => (
          <span
            className="text-danger cursor-pointer"
            id={value}
            onClick={() => handleDeleteClick(value, selectedTrip.tableId)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        ),
      },
    },
  ];
  const columnsPrint = [
    {
      name: "id",
      label: "Nº",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => rowIndex + 1,
      },
    },
    {
      name: "pasajero",
      label: "Pasajero",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <div className="d-flex flex-column">
            <span>{passengers[rowIndex].pasajero}</span>
            {passengers[rowIndex].salud !== "" ||
            passengers[rowIndex].salud !== "No" ? (
              <span style={{ fontSize: "smaller" }}>
                Salud: {passengers[rowIndex].salud}
              </span>
            ) : (
              ""
            )}
          </div>
        ),
      },
    },
    {
      name: "dni",
      label: "D.N.I",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <div className="d-flex flex-column">
            <span>{passengers[rowIndex].dni}</span>
            <span style={{ fontSize: "smaller" }}>
              {passengers[rowIndex].fechavenc
                ? Moment(passengers[rowIndex].fechavenc).format("DD/MM/YYYY")
                : null}
            </span>
          </div>
        ),
      },
    },
    {
      name: "fechanac",
      label: "F. de Nac.",
      options: {
        setCellProps: () => ({
          style: { minWidth: "110px", maxWidth: "110px", padding: "6px" },
        }),
        customBodyRender: (value) => Moment(value).format("DD/MM/YYYY"),
      },
    },
    {
      name: "sexo",
      label: <FontAwesomeIcon icon={faVenusMars} />,
      options: {
        display: "excluded",
      },
    },
    {
      name: "telefono",
      label: "Telefono",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        display: "excluded",
      },
    },
    {
      name: "localidad",
      label: "Loc.",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        customBodyRender: (value) => <span>{capitalize(value)}</span>,
      },
    },
    {
      name: "salud",
      label: "Salud",
      options: {
        display: "excluded",
      },
    },
    {
      name: "paymentsId",
      label: "PaymentsID",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "totalPesos",
      label: "$",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
      },
    },
    {
      name: "totalDol",
      label: "U$S",
      options: {
        setCellProps: () => ({
          style: { minWidth: "60px", maxWidth: "60px", padding: "6px" },
        }),
      },
    },
    {
      name: "totalExc",
      label: "Exc.",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
      },
    },
    {
      name: "vendedora",
      label: "Vend.",
      options: {
        display: "excluded",
      },
    },
    {
      name: "comentario",
      label: "Comentario",
      options: {
        display: "excluded",
      },
    },
    {
      name: "dni",
      label: <FontAwesomeIcon icon={faEdit} />,
      options: {
        display: "excluded",
      },
    },
    {
      name: "dni",
      label: <FontAwesomeIcon icon={faTimes} />,
      options: {
        display: "excluded",
      },
    },
  ];
  const columnsPdf = [
    {
      name: "id",
      label: "Nº",
      options: {
        display: "excluded",
      },
    },
    {
      name: "pasajero",
      label: "Pasajero",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <div className="d-flex flex-column">
            <span>{passengers[rowIndex].pasajero}</span>
            {passengers[rowIndex].salud !== "" ||
            passengers[rowIndex].salud !== "No" ? (
              <span style={{ fontSize: "smaller" }}>
                Salud: {passengers[rowIndex].salud}
              </span>
            ) : (
              ""
            )}
          </div>
        ),
      },
    },
    {
      name: "dni",
      label: "D.N.I",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
      },
    },
    {
      name: "fechanac",
      label: "F. de Nac.",
      options: {
        setCellProps: () => ({
          style: { minWidth: "110px", maxWidth: "110px", padding: "6px" },
        }),
      },
    },
    {
      name: "sexo",
      label: "Sexo",
    },
    {
      name: "telefono",
      label: "Telefono",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        display: "excluded",
      },
    },
    {
      name: "localidad",
      label: "Localidad",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        customBodyRender: (value) => <span>{capitalize(value)}</span>,
      },
    },
    {
      name: "salud",
      label: "Salud",
      options: {
        display: "excluded",
      },
    },
    {
      name: "paymentsId",
      label: "PaymentsID",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "totalPesos",
      label: "($)",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        display: "excluded",
      },
    },
    {
      name: "totalDol",
      label: "(U$S)",
      options: {
        setCellProps: () => ({
          style: { minWidth: "60px", maxWidth: "60px", padding: "6px" },
        }),
        display: "excluded",
      },
    },
    {
      name: "totalExc",
      label: "Exc.",
      options: {
        setCellProps: () => ({ style: { padding: "6px" } }),
        display: "excluded",
      },
    },
    {
      name: "comentario",
      label: "Comentario",
      options: {
        display: "excluded",
      },
    },
    {
      name: "dni",
      label: <FontAwesomeIcon icon={faEdit} />,
      options: {
        display: "excluded",
      },
    },
    {
      name: "dni",
      label: <FontAwesomeIcon icon={faTimes} />,
      options: {
        display: "excluded",
      },
    },
  ];

  let componentRef = useRef(null);

  const optionsPrint = {
    responsive: "standard",
    expandableRows: false,
    rowsPerPage: 70,
    elevation: 0,
    pagination: false,
    selectableRows: "none",
    sortOrder: {
      name: "pasajero",
      direction: "asc",
    },
  };
  const options = {
    responsive: "standard",
    rowsPerPage: 120,
    searchPlaceholder: "Buscar...",
    elevation: 0,
    pagination: false,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: "No hay información disponible.",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ocultar Columnas",
        filterTable: "Filtrar Tabla",
      },
      filter: {
        all: "Todos",
        title: "Filtros",
        reset: "Reiniciar",
      },
      viewColumns: {
        title: "Ver Columnas",
        titleAria: "Ver/Ocultar Columnas de la Tabla",
      },
    },
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            {rowData[8] != "" ? (
              <div id="paymentData">
                <div className="row justify-content-flex-start border-bottom border-2 pt-2 my-1 fw-bold">
                  <div className="subrow-first">Id</div>
                  <div className="col-1">Tipo</div>
                  <div className="col-1">Fecha</div>
                  <div className="col-1">Total $</div>
                  <div className="col-1">Total U$S</div>
                  <div className="col-2">Medio de Pago</div>
                  <div className="col-3">
                    <div>
                      <p>Comentario</p>
                    </div>
                  </div>
                </div>
                {recibos
                  .filter(
                    (recibo) =>
                      recibo.paymentsId == rowData[8] && rowData[8] != ""
                  )
                  .map((r) => (
                    <div className="row justify-content-center border-bottom py-2 my-1">
                      <div className="subrow-first">000{r.id}</div>
                      <div className="col-1">{r.tipo}</div>
                      <div className="col-1">
                        {Moment(r.fecha).format("DD/MM/YYYY")}
                      </div>
                      <div className="col-1">$ {r.totalPesos}</div>
                      <div className="col-1">U$S {r.totalDol}</div>
                      <div className="col-2">{r.medioDePago}</div>
                      <div className="col-5">
                        <div>
                          <p>{r.comentario}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="col my-4 py-0 fs-6">
                  <span className="fw-bold">Total Pesos: </span>${rowData[9]}
                  <span className="fw-bold"> | Total U$S: </span>
                  {rowData[10]}
                  <span className="fw-bold"> | Total Excursiones: </span>$
                  {rowData[11]}
                </div>
                <div className="col my-4 py-0 fs-6">
                  <span className="fw-bold">Comentario: </span>
                  {rowData[12] != "" ? rowData[12] : "No se emitió comentario."}
                </div>
              </div>
            ) : (
              <div className="row justify-content-center my-3">
                El pasajero no registra ningún pago correspondiente al destino.
              </div>
            )}
          </TableCell>
        </TableRow>
      );
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) =>
      console.log(curExpanded, allExpanded, rowsExpanded),
  };

  useEffect(() => {
    getTrips();
  }, []);

  const getTrips = async () => {
    Axios.get(baseUrl + "/getTripsData").then((response) => {
      setTrips(response.data);
    });
  };

  const getPassengersData = async (e) => {
    setSelectedTrip(trips[e.target.selectedIndex - 1]);
    setMaxPassengers(trips[e.target.selectedIndex - 1].paxMax);
    setTripDate(trips[e.target.selectedIndex - 1].fecha);
    handleGetPassengersData(e.target.value);
  };

  const handleGetPassengersData = (e) => {
    Axios.get(baseUrl + "/getPassengersData", {
      params: { selectedTrip: e },
    }).then((response) => {
      setPassengers(response.data);
      setTotalPassengers(response.data.length);
      console.log(response.data);
    });
    getRecibosData(e);
  };

  const getRecibosData = async (e) => {
    Axios.get(baseUrl + "/getRecibosData").then((response) => {
      setRecibos(response.data);
    });
  };

  const handleModalAddPassenger = async () => {
    setModalAddIsOpen(true);
  };

  const handleDeleteClick = async (value, trip) => {
    Axios.post(baseUrl + "/deletePassenger", {
      passengerId: value,
      selectedTrip: trip,
    });
    setPassengers(passengers.filter((passenger) => passenger.id != value));
    toast.success("¡Exito! Pasajero eliminado");
  };

  const reactToPrintContent = () => {
    return componentRef;
  };

  const setComponentRef = (ref) => {
    componentRef = ref;
  };

  const reactToPrintTrigger = () => {
    setToPdf(false);
    return (
      <button type="button" class="btn btn-primary me-2">
        <FontAwesomeIcon icon={faPrint} />
      </button>
    );
  };

  const editPassengerOpen = (value) => {
    setPassengerToEdit(
      passengers.filter((passenger) => passenger.id == value)[0]
    );
    setEditPassengerIsOpen(true);
  };

  const downloadPdf = (fileName) => {
    setToPdf(true);
    const element = document.getElementById("tableToPdf").innerHTML;
    const options = {
      image: { type: "png" },
      filename: fileName + ".pdf",
    };
    html2pdf(element, options);
  };

  return (
    <div id="Pax">
      <Breadcrumb title="Pax" />
      <div className="container-big mb-5">
        <div
          id="paxInfo"
          className="row mb-2 mt-2 justify-content-center align-items-center flex-column"
        >
          <div className="col-6">
            <select
              className="form-select"
              onChange={getPassengersData}
              defaultValue="default"
            >
              <option value="default" disabled hidden>
                Seleccione un destino
              </option>
              {trips.map((trip) => (
                <option value={trip.tableId}>
                  {trip.nombre} ({Moment(trip.fecha).format("DD/MM/YYYY")})
                </option>
              ))}
            </select>
          </div>
        </div>
        {selectedTrip ? (
          <div>
            <div
              className="col d-flex justify-content-center mb-4"
              data-aos="fade-in"
            >
              <button
                type="button"
                className="btn btn-success me-2"
                onClick={handleModalAddPassenger}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
              <ReactToPrint
                content={reactToPrintContent}
                documentTitle="PAX"
                trigger={reactToPrintTrigger}
              />
              <button
                type="button"
                className="btn btn-primary me-2"
                onClick={() =>
                  downloadPdf(
                    "PAX - " +
                      selectedTrip.nombre +
                      " " +
                      Moment(selectedTrip.fecha).format("DD-MM-YYYY")
                  )
                }
              >
                <FontAwesomeIcon icon={faFilePdf} />
              </button>
              <button type="button" className="btn btn-danger me-2">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>

            <div
              data-aos="fade-in"
              style={{ display: totalPassengers > 0 ? "flex" : "none" }}
              className="col align-items-center fs-6 pt-3"
            >
              <span className="col-4 text-start">
                Pasajeros Totales: {totalPassengers}
              </span>
              <span className="col-4 text-center">
                Pasajeros Maximos: {maxPassengers}
              </span>
              <span className="col-4 text-end">Fecha: {tripDate}</span>
            </div>

            <div
              id="tableToPrint"
              className="table-print flex-column overflow-hidden"
              ref={setComponentRef}
            >
              <div>
                <div className="logo-print d-flex justify-content-center">
                  <img src={logo} alt="logo" className="w-30" />
                </div>
                <div className="col bg-primary text-white fs-4 fw-600 text-center mt-3 text-uppercase">
                  {selectedTrip ? selectedTrip.nombre : null}
                </div>
                <div className="col bg-darkblue text-white fs-6 fw-600 text-center text-uppercase">
                  {Moment(tripDate).format("DD/MM/YYYY")}
                </div>

                <div className="col bg-primary text-white fs-6 fw-600 text-center text-uppercase">
                  Pasajeros Totales: {totalPassengers}
                </div>
                <ThemeProvider theme={createTheme()}>
                  <MUIDataTable
                    data={passengers}
                    columns={columnsPrint}
                    options={optionsPrint}
                  />
                </ThemeProvider>
              </div>
            </div>

            <div
              id="tableToPdf"
              className="table-print flex-column overflow-hidden"
            >
              <div className="logo-print d-flex justify-content-center">
                <img src={logo} alt="logo" className="w-30" />
              </div>
              <div className="col bg-primary text-white fs-4 fw-600 text-center mt-3 text-uppercase">
                {selectedTrip ? selectedTrip.nombre : null}
              </div>
              <div className="col bg-darkblue text-white fs-6 fw-600 text-uppercase px-2 d-flex justify-content-between">
                <span>Pasajeros Totales: {totalPassengers}</span>
                <span>{Moment(tripDate).format("DD/MM/YYYY")}</span>
              </div>
              <div>
                <ThemeProvider theme={createTheme()}>
                  <MUIDataTable
                    data={passengers}
                    columns={columnsPdf}
                    options={optionsPrint}
                  />
                </ThemeProvider>
              </div>
            </div>

            <div id="tableWithData" data-aos="fade-in">
              <ThemeProvider theme={createTheme()}>
                <MUIDataTable
                  data={passengers}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </div>

            <ModalAdd
              passengers={passengers}
              setPassengers={setPassengers}
              modalAddIsOpen={modalAddIsOpen}
              setModalAddIsOpen={setModalAddIsOpen}
              selected={selectedTrip ? selectedTrip.tableId : null}
              firebaseUser={firebaseUser}
            />

            <EditPassenger
              handleGetPassengersData={handleGetPassengersData}
              editPassengerIsOpen={editPassengerIsOpen}
              setEditPassengerIsOpen={setEditPassengerIsOpen}
              passengerToEdit={passengerToEdit}
              setPassengerToEdit={setPassengerToEdit}
              passengers={passengers}
              setPassengers={setPassengers}
              selectedTrip={selectedTrip ? selectedTrip.tableId : null}
            />
          </div>
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center my-5"
            data-aos="fade-in"
          >
            <img src={EmptyState1} alt="Empty" />
            <span className="fs-4 text-dark opacity-25 mt-2">
              No hay información disponible
            </span>
            <span className="text-light text-dark opacity-25 fs-6">
              Debes seleccionar un destino
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Pax;
