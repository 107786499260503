import OurTripsBox from './our-trips-box';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import baseUrl from "../baseUrl";
import WorldMap from '../images/WorldMap.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../loader';

const OurTrips = () => {

    const [tripsToShow, setTripsToShow] = useState([]);

    useEffect(() => {
        getTrips();
    }, []);

    const getTrips = async () => {
        Axios.get(baseUrl + "/getTripsData").then((response) => {
            setTripsToShow(response.data);
        });
    }

    return (
        <div style={{ background: `url(${WorldMap})`, backgroundSize: "cover" }}>
            <div className="container-small mt-5">
                <div className="row row mb-5 align-items-end ">
                    <div className="col-md-6">
                        <div className="section-title sm-text-center">
                            <h2 className="fw-900 fs-50 text-orange">EXPERIENCIAS</h2>
                            <h2 className="fs-36">INIGUALABLES</h2>
                        </div>

                    </div>
                    <div className="col-md-6 text-md-start sm-none d-flex justify-content-center align-items-center">
                        <span className="fw-bold sm-text-center">Acompañanos a vivir experiencias únicas junto a nuestro equipo de trabajo.</span>
                    </div>
                </div>

                {tripsToShow.length > 0 ?
                    <div className="row">
                        {tripsToShow.slice(0, 6).map((data) => (
                            <OurTripsBox title={data.nombre} price={data.precio} exterior={data.exterior} estadia={data.estadia} transporte={data.transporte} fecha={data.fecha} days={data.dias} noches={data.noches} imageLink={data.imgpath} />
                        ))}

                    </div>

                    :
                    <Loader />
                }

                <div className="row">
                    <div className="d-flex justify-content-end mt-4 p-0">
                        <Link to="/nuestros-destinos" className="btn bg-gray text-white fw-600 border-radius-0 fs-8">
                            <FontAwesomeIcon icon={faCaretRight} className="text-orange me-2" /> VER TODOS
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OurTrips;