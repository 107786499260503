import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlane,
  faHotel,
  faUserShield,
  faCheck,
  faBus,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import baseUrl from "../baseUrl";
import { Carousel } from "react-bootstrap";

const HomeCarousel = () => {
  const [trips, setTrips] = useState([]);

  useEffect(() => {
    getTrips();
  }, []);

  const getTrips = async () => {
    await Axios.get(baseUrl + "/getTripsData").then((response) => {
      setTrips(response.data?.slice(0, 3));
    });
  };

  return (
    <div>
      <Carousel fade>
        {trips.map((trip) => (
          <Carousel.Item>
            <div className="carousel-inner main-carousel-size">
              <div
                className="carousel-item active main-carousel-size outstanding-trip"
                style={{
                  backgroundImage: `linear-gradient(0deg, rgb(199 55 0 / 70%) 0%, rgb(255 158 25 / 70%) 100%), url("${trip.imgpath}")`,
                  backgroundBlendMode: "inherit",
                }}
              >
                <div className="carousel-mask d-flex align-items-end">
                  <div
                    className="container-small"
                    style={{ paddingBottom: "7rem" }}
                  >
                    <div className="sm-flex-column sm-align-items-center sm-text-center d-flex justify-content-between h-100 w-100 text-white">
                      <div className="w-60 lh-1">
                        <div className="fs-3rem text-uppercase text-shadow-1 mb-3">
                          IMPERDIBLE
                        </div>
                        <div className="fs-5rem text-uppercase fw-900 text-shadow-1 mb-3">
                          {trip.nombre}
                        </div>
                        <div className="fs-7 fw-600 carousel-text fw-500 text-shadow-1 lh-150">
                          {trip.descripcion.substr(0, 150)}...
                        </div>
                      </div>

                      <div
                        id="trip-info"
                        className="d-flex flex-column justify-content-end"
                      >
                        <div className="d-flex flex-row w-100 text-center fs-7">
                          <div className="transport bg-orange p-3 col-4">
                            <FontAwesomeIcon
                              icon={trip.transporte == "bus" ? faBus : faPlane}
                            />
                          </div>
                          <div className="bg-gray p-3 col-4">
                            <FontAwesomeIcon icon={faHotel} />
                          </div>
                          <div className="bg-darkGray p-3 col-4">
                            <FontAwesomeIcon icon={faUserShield} />
                          </div>
                        </div>

                        <div className="bg-white py-4 px-4 d-flex flex-column lh-1">
                          <span className="text-gray">VALOR</span>
                          <div>
                            <span className="text-gray me-2 fw-bold fs-2rem">
                              {trip.exterior == "0" ? "$" : "U$S"}
                            </span>
                            <span className="text-orange fw-900 fs-3rem">
                              {trip.precio}
                            </span>
                          </div>
                        </div>

                        <div className="bg-darkGray p-3">
                          <div className="text-center">
                            <span className="fw-600 cursor-pointer">
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-orange me-2"
                              />
                              VER MÁS
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default HomeCarousel;
