import NewUser from '../../components/admin/new-user';
import EditUser from '../../components/admin/edit-user';
import DeleteUser from '../../components/admin/delete-user';
import Breadcrumb from '../../components/breadcrumb';

const Usuarios = () => {
    return (
        <div id="Container">
            <Breadcrumb title="Usuarios" />

            <div id="Users" className="container">
                <div className="row">
                    <div className="col" data-aos="fade-in">
                        <NewUser />
                    </div>
                    <div className="col" data-aos="fade-in">
                        <EditUser />
                    </div>
                    <div className="col" data-aos="fade-in">
                        <DeleteUser />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Usuarios;