import Breadcrumb from "../components/breadcrumb";
import NuestrosDestinosBox from "../components/nuestros-destinos-box";
import { useEffect, useState } from "react";
import Axios from 'axios';
import baseUrl from '../baseUrl';
import Loader from "../loader";


const NuestrosDestinos = () => {

    const [arrayDestinos, setArrayDestinos] = useState([]);

    useEffect(() => {
        getTrips();
    }, [])

    const getTrips = async () => {
        Axios.get(baseUrl + "/getTripsData").then((response) => {
            setArrayDestinos(response.data);
        });
    }

    return (
        <div id="nuestros-destinos">
            <Breadcrumb title="Nuestros Destinos" />
            <div className="container">
                {arrayDestinos.length > 0 ?

                    <div className="row">

                        {arrayDestinos.map((destino) => (
                            <NuestrosDestinosBox trip={destino} />
                        ))}
                    </div>
                    :
                    <Loader />
                }


            </div>
        </div>
    )
}

export default NuestrosDestinos;