import LogoWhite from '../images/LogoWhite.png';
import { Link } from 'react-router-dom';

//EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
    return (
        <div id="footer" className="bg-orange text-white">
            <div id="footer-upper" className="bg-darkGray p-3">

            </div>
            <div className="container-small">
                <div id="footer-lower" className="d-flex mx-3 py-4">
                    <div id="lower-left" className="col d-flex flex-column sm-text-center sm-align-items-center">
                        <div id="socialMedia" className="d-flex flex-row mb-3 fs-5">
                            <a className="d-flex flex-column" target="_blank" href="https://www.facebook.com/taiyoviajesturismo">
                                <span className="btn-footer-links p-3 me-4 position-relative">
                                    <FontAwesomeIcon icon={faFacebook} />
                                </span>
                            </a>
                            
                            <a className="d-flex flex-column" target="_blank" href="https://www.instagram.com/taiyoviajes/">
                            <span className="btn-footer-links p-3 me-4 position-relative">
                                <FontAwesomeIcon icon={faInstagram} />
                            </span>
                            </a>

                            <a className="d-flex flex-column" target="_blank" href="https://www.youtube.com/channel/UCVHAvQsl86TwlRhx82NfNcQ">
                            <span className="btn-footer-links p-3 me-4 position-relative">
                                <FontAwesomeIcon icon={faYoutube} />
                            </span>
                            </a>
                        </div>
                        <span className="mt-2">© Taiyo Viajes {new Date().getFullYear()} - Todos los derechos reservados</span>
                        <div>Creado por <span className="fw-bold">URBAN S.I</span></div>
                    </div>
                    <div id="lower-right" className="col text-end d-flex flex-column">
                        <div className="mb-3"><img src={LogoWhite} alt="Logo" /></div>
                        <span>Turismo Nacional e Internacional</span>
                        <span className="fw-bold">Legajo 16419 EVT</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;