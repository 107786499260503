const Loader = () => {
    return (
        <div style={{ height: "70vh" }}>
            <div id="preloader" className="text-gray">
                <div id="loader"></div>
                <span className="fs-6 text-darkGray fw-500 mt-3">CARGANDO</span>
            </div>
        </div>
    )
}

export default Loader;