import { styled } from "styled-components";
import Spinner from "../Spinner/Spinner";

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;

export default function Button({
  disabled,
  text,
  className,
  isLoading,
  form,
  type,
  variant,
  onClick,
}) {
  const getVariantClassName = () => {
    switch (variant) {
      case "PRIMARY":
        return "btn-primary";

      default:
        return "btn-light";
    }
  };

  return (
    <button
      type={type ?? "button"}
      form={form}
      className={`btn ${getVariantClassName()} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      <Wrapper>
        {text}
        {isLoading && <Spinner color={"#fff"} />}
      </Wrapper>
    </button>
  );
}
