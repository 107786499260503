import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import Axios from "axios";
import { toast } from "react-toastify";
import baseUrl from "../../baseUrl";
import { capitalize } from "../../functions";
import { FloatingLabel, Form } from "react-bootstrap";

const AddPassengerModal = ({
  modalAddIsOpen,
  setModalAddIsOpen,
  selected,
  firebaseUser,
  passengers,
  setPassengers,
}) => {
  const passengerInitialValues = {
    pasajero: "",
    dni: "",
    fechanac: "",
    fechavenc: "",
    salud: "",
    sexo: "No",
    email: "",
    telefono: "",
    localidad: "",
    comentario: "",
    paymentsId: "",
    butaca: "",
    habitacion: "",
    totalPesos: 0,
    totalDol: 0,
    totalExc: 0,
    vendedora: "",
    agregadoPor: "",
  };

  const [passengerData, setPassengerData] = useState(passengerInitialValues);
  const [dbData, setDbData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSuggestionId, setSelectedSuggestionId] = useState();

  useEffect(() => {
    getDbData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const unique_id = uuid();
    setPassengerData({
      ...passengerData,
      [name]: value,
      paymentsId: unique_id,
      agregadoPor: firebaseUser.displayName,
    });
  };

  const handleClose = () => {
    setModalAddIsOpen(false);
    setPassengerData(passengerInitialValues);
  };

  const submit = (e) => {
    e.preventDefault();
    addNewPassenger(passengerData);
    setPassengers([...passengers, passengerData]);
  };

  const addNewPassenger = async (dataToPush) => {
    await Axios.post(baseUrl + "/addNewPassenger", {
      passenger: dataToPush,
      selectedTrip: selected,
    });

    const isNewPassenger =
      dbData.filter((passenger) => passenger.dni === String(dataToPush.dni))
        .length > 0
        ? true
        : false;
    const dataForDb = {
      id: selectedSuggestionId,
      pasajero: dataToPush.pasajero,
      dni: dataToPush.dni,
      telefono: dataToPush.telefono,
      fechanac: dataToPush.fechanac,
      fechavenc: dataToPush.fechavenc,
      email: dataToPush.email,
      sexo: dataToPush.sexo,
      localidad: dataToPush.localidad,
      salud: dataToPush.salud,
      pasaporte: "",
      vendedora: dataToPush.vendedora,
    };

    await Axios.post(baseUrl + "/addOrUpdateDbData", {
      passenger: dataForDb,
      isNotNew: isNewPassenger,
    })
      .then(() => toast.success("¡Exito! Pasajero agregado"))
      .catch(() =>
        toast.error("¡Error! Ocurrió un problema al actualizar el pasajero")
      );

    setModalAddIsOpen(false);
    setPassengerData(passengerInitialValues);
  };

  const getDbData = async () => {
    await Axios.get(baseUrl + "/getDbData").then((response) => {
      setDbData(response.data);
    });
  };

  const getSuggestions = () => {
    if (suggestions.length === 0 && inputValue !== "") {
      return (
        <ul
          className="dropdown-menu"
          style={{ display: showDropdown ? "block" : "none" }}
        >
          <span className="dropdown-item-text">No hay coincidencias</span>
        </ul>
      );
    }
    return (
      <ul
        className="dropdown-menu"
        style={{ display: showDropdown ? "block" : "none" }}
      >
        <li>
          <h6 class="dropdown-header">Sugerencias en Base de Datos</h6>
        </li>
        {suggestions.map((suggestion, index) => (
          <div key={index}>
            <li
              onMouseDown={() => setSuggestionData(suggestion)}
              className="dropdown-item cursor-pointer"
            >
              {capitalize(suggestion.pasajero)}
            </li>
          </div>
        ))}
      </ul>
    );
  };

  const onNameChange = (e) => {
    setShowDropdown(true);
    if (e.target.value.length > 0) {
      setSuggestions(
        dbData
          .sort()
          .filter((suggestion) =>
            suggestion.pasajero
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
      );
    } else {
      setShowDropdown(false);
    }
    setInputValue(e.target.value);
  };

  const dropdownDisplay = (bool) => {
    setShowDropdown(bool);
  };

  const dropdownBlur = (bool, e) => {
    setShowDropdown(bool);
  };

  const setSuggestionData = (suggestion) => {
    setShowDropdown(false);
    setSelectedSuggestionId(suggestion.id);
    setPassengerData({
      ...passengerData,
      pasajero: suggestion.pasajero,
      dni: suggestion.dni,
      telefono: suggestion.telefono,
      email: suggestion.email,
      salud: suggestion.salud,
      sexo: suggestion.sexo,
      localidad: suggestion.localidad,
      fechanac: suggestion.fechanac,
      fechavenc: suggestion.fechavenc,
      vendedora: suggestion.vendedora,
    });
  };

  return (
    <Modal show={modalAddIsOpen} backdrop="static" onHide={handleClose}>
      <Modal.Header closeButton>Agregar Pasajero</Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          <div className="input-group mb-3 add-passenger-input-name">
            <FloatingLabel label="Pasajero" className="w-100">
              <Form.Control
                className="form-control"
                type="text"
                name="pasajero"
                value={
                  passengerData.pasajero && capitalize(passengerData.pasajero)
                }
                placeholder="Apellido y Nombre"
                autoComplete="off"
                onChange={(e) => {
                  onNameChange(e);
                  handleInputChange(e);
                }}
                onFocus={() => dropdownDisplay(true)}
                onBlur={(e) => dropdownBlur(false, e)}
              />
            </FloatingLabel>
            {getSuggestions()}
          </div>
          <div class="input-group mb-3">
            <FloatingLabel label="Fecha de Nacimiento" className="w-100">
              <Form.Control
                className="form-control"
                type="date"
                name="fechanac"
                value={passengerData.fechanac}
                placeholder="Fecha de Nacimiento"
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              />
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="D.N.I" className="w-100">
              <Form.Control
                className="form-control"
                type="number"
                name="dni"
                value={passengerData.dni}
                placeholder="D.N.I"
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              />
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="Fecha de Vencimiento DNI" className="w-100">
              <Form.Control
                className="form-control"
                type="date"
                name="fechavenc"
                value={passengerData.fechavenc}
                placeholder="Fecha de Vencimiento DNI"
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              />
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="Telefono" className="w-100">
              <Form.Control
                className="form-control"
                type="number"
                name="telefono"
                value={passengerData.telefono}
                placeholder="Teléfono"
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              />
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="E-mail" className="w-100">
              <Form.Control
                className="form-control"
                type="text"
                name="email"
                value={passengerData.email}
                placeholder="E-Mail"
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              />
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="Localidad" className="w-100">
              <Form.Control
                className="form-control"
                type="text"
                name="localidad"
                value={passengerData.localidad}
                placeholder="Ciudad"
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              />
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="Sexo" className="w-100">
              <select
                className="form-select"
                name="sexo"
                defaultValue="default"
                value={passengerData.sexo}
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              >
                <option value="No" hidden disabled></option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="Salud" className="w-100">
              <select
                className="form-select"
                name="salud"
                defaultValue=""
                value={passengerData.salud}
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              >
                <option value="" hidden disabled></option>
                <option value="No">No</option>
                <option value="Diabetes">Diabetes</option>
                <option value="Celíaco">Celíaco</option>
                <option value="Vegetariano">Vegetariano</option>
                <option value="Hipertensión">Hipertensión</option>
                <option value="Hipotensión">Hipotensión</option>
                <option value="Diverticulosis">Diverticulosis</option>
                <option value="Otro">Otro</option>
              </select>
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="Vendedora" className="w-100">
              <select
                className="form-select"
                name="vendedora"
                defaultValue=""
                value={passengerData.vendedora}
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              >
                <option value="" hidden disabled></option>
                <option value="No">No</option>
                <option value="Caro">Caro</option>
                <option value="C.Ganci">C. Ganci</option>
                <option value="G.Eggs">G. Eggs</option>
                <option value="J.Pablo">J. Pablo</option>
                <option value="Mariana">Mariana</option>
                <option value="Oficina">Oficina</option>
                <option value="P.Matías">P. Matías</option>
                <option value="Piccolo">Piccolo</option>
                <option value="Recagno">Recagno</option>
                <option value="Santoro">Santoro</option>
              </select>
            </FloatingLabel>
          </div>

          <div class="input-group mb-3">
            <FloatingLabel label="Comentario" className="w-100">
              <Form.Control
                className="form-control"
                type="text"
                name="comentario"
                placeholder="Comentario"
                onChange={handleInputChange}
                onFocus={() => dropdownDisplay(false)}
              />
            </FloatingLabel>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-light" onClick={handleClose}>
          Cancelar
        </button>
        <button type="button" class="btn btn-primary" onClick={submit}>
          Guardar
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddPassengerModal;
