import { db } from '../../firebase';
import { collection, addDoc, initializeFirestore } from "firebase/firestore";
import { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile  } from "firebase/auth";


const NewUser = () => {

    const initialStateValues = {
        nombre: "",
        apellido: "",
        email: "",
        telefono: "",
        pass: "",
        rol: "",
        id: "",
    };

    const initialValidationValues = {
        vName: false,
        vApellido: false,
        vEmail: false,
        vTelefono: false,
        vPass: false,
        vRol: false,
    };

    const [userValues, setUserValues] = useState(initialStateValues);
    const [validationValues, setValidationValue] = useState(initialValidationValues);
    const [errorDisplay, setErrorDisplayValue] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserValues({ ...userValues, [name]: value });
        if (value != "")
            setValidationValue({ ...validationValues, [name]: true });
    };

    const handleValidation = () => {
        if (validationValues.vName == true && validationValues.vApellido == true && validationValues.vEmail == true && validationValues.vTelefono == true && validationValues.vPass == true && validationValues.vRol == true) {
            return true;
        } else {
            return true;
        }
    }

    const handleSubmit = (e) => {
        if (handleValidation() == true) {
            e.preventDefault();
            //GENERATE UNIQUE ID AND PUSH INTO FIREBASE
            addNewUser(userValues);
        } else {
            e.preventDefault();
            setErrorDisplayValue(true);
        }
    }

    const addNewUser = async (userData) => {
        //await addDoc(collection(db, "users"), userData);
        const email = userData.email;
        const password = userData.pass;

        createUserWithEmailAndPassword(getAuth(),email, password)
            .then(function (result) {
                return updateProfile(result.user,{
                    displayName: userData.nombre+" "+userData.apellido,
                    photoURL: userData.rol,
                    phoneNumber: userData.telefono,
                    providerId: userData.rol,
                })
            }).catch(function (error) {
                console.log(error);
            });
    }

    const rebootValues = () => {
        document.getElementById("newUserForm").reset();
        setUserValues(initialStateValues);
    }

    return (
        <div>
            <form onSubmit={handleSubmit} className="card card-body" id="newUserForm">
                <h4 className="pb-4 mb-4 border-bottom">NUEVO USUARIO</h4>
                <div className="mb-3">
                    <input type="text" name="nombre" className="form-control" placeholder="Nombre" onChange={handleInputChange} />
                </div>

                <div className="mb-3">
                    <input type="text" name="apellido" className="form-control" placeholder="Apellido" onChange={handleInputChange} />
                </div>

                <div className="mb-3">
                    <input type="text" name="email" className="form-control" placeholder="E-Mail" onChange={handleInputChange} />
                </div>

                <div className="mb-3">
                    <input type="text" name="telefono" className="form-control" placeholder="Teléfono" onChange={handleInputChange} />
                </div>

                <div className="mb-3">
                    <input type="password" name="pass" className="form-control" placeholder="Contraseña" onChange={handleInputChange} />
                </div>

                <div className="mb-3 d-flex flex-column">
                    <span className="mb-2">Rol:</span>
                    <div className="options">
                        <div class="form-check form-check-inline">
                            <input name="rol" class="form-check-input" type="radio" id="newRadio1" value="Administrador" onChange={handleInputChange} />
                            <label class="form-check-label" for="inlineRadio1">Administrador</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="rol" class="form-check-input" type="radio" id="newRadio2" value="Secretario" onChange={handleInputChange} />
                            <label className="form-check-label" for="inlineRadio2">Secretario</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input name="rol" class="form-check-input" type="radio" id="newRadio3" value="Vendedor" onChange={handleInputChange} />
                            <label className="form-check-label" for="inlineRadio3">Vendedor</label>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <button className="btn btn-light me-2" type="button" onClick={rebootValues}>REINICIAR</button>
                    <button className="btn btn-primary" type="submit">CREAR</button>
                </div>

                <div className={errorDisplay ? "mt-3 mb-1" : "d-none"}>
                    <span className="text-danger fs-8">* Completa todos los campos obligatorios para continuar.</span>
                </div>

            </form>

        </div>
    )
}

export default NewUser;