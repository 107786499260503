import Breadcrumb from '../components/breadcrumb';

const QuienesSomos = () => {
    return (
        <div>
            <Breadcrumb title="Nosotros" />
            <div className="container-small fs-5 fs-sm-08rem my-4">
                <div className="col-12 d-flex flex-row flex-wrap justify-content-around text-justify">
                    <div className="col-12 col-sm-6">
                        <p>Taiyo Viajes es una Empresa que comercializa paquetes turísticos en el ámbito nacional e internacional, simplemente porque ama viajar y disfrutar del turismo, sobre todo entendido al turismo como una experiencia para enriquecer el alma, visitando nuevos lugares y culturas.</p>
                        <p>La principal premisa de Taiyo viajes es el trabajo en equipo, no solo de los distintos componentes de la Empresa sino también con los Hoteleros y transportistas, este principio de trabajo hace que se logre un ambiente agradable y distendido en cada una de nuestras propuestas, indispensable para que el turista disfrute de su viaje y de la hospitalidad.</p>
                    </div>

                    <img className="col-12 col-sm-4 img-effect" src="https://scontent.faep9-1.fna.fbcdn.net/v/t39.30808-6/274238018_3542351202555942_3194072328234545601_n.jpg?_nc_cat=109&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeFwfRLWnEQRhYBSX0CPULZ12q8Fs8SAeIbarwWzxIB4hjbheyyrKqm2cEq0vQdF0cE&_nc_ohc=r1l6WigRb4gAX8ms_Sh&_nc_ht=scontent.faep9-1.fna&oh=00_AT_cR1uIQ7d2Ti62F87890PD3c4bVUF3xlVHPBULi4u5Xg&oe=622BAB3B" />

                    <img className="col-12 col-sm-5 mt-5" src="https://scontent.faep9-2.fna.fbcdn.net/v/t39.30808-6/262114257_3238002962990769_6707578673413770493_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeGbiBarRBnGQsPisESONDF-tiF6eS6fBpy2IXp5Lp8GnPWhBh5fDPcXY7yRWn9VOjc&_nc_ohc=h6sca61ZNMMAX9uXc7v&tn=TxChF710AZNVkM2n&_nc_ht=scontent.faep9-2.fna&oh=00_AT-c8N5LaHu8C2NBftfjEP1OwFX20t44MPy1S2zs35Mwmg&oe=622C30B5"/>               

                    <div className="col-12 col-sm-6 mt-5">
                        <p>La palabra Taiyo proviene del idioma japonés y significa en castellano Sol, esta simboliza la luz y la calidez, sin olvidar el significado que tuvo y tiene el Sol en tantas culturas antiguas y modernas.</p>
                        <p>La experiencia de realizar un viaje turístico con Taiyo Viajes se resume en el servicio, desde el momento en que el viajero ingresa a nuestra oficina, pasando por el viaje propiamente dicho y por supuesto la hospitalidad.</p>
                        <p>El Turismo es una actividad placentera y hermosa que nos apasiona, pero por sobre todas las cosas porque amamos lo que hacemos: “Brindar servicios de turismo”.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default QuienesSomos;