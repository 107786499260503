import Modal from 'react-bootstrap/Modal';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from 'react';
import { toast } from 'react-toastify';

// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey } from '@fortawesome/free-solid-svg-icons';

const LogInModal = ({ isOpen, setIsOpen }) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const auth = getAuth();
    
    const handleClose = () => { setIsOpen(false) };

    const submit = () => {

        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            toast.success("¡Exito! Ha iniciado sesión");
            toast.info("Bienvenido "+user.displayName, {autoClose: 8000} );
            setIsOpen(false);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toast.error("Usuario y/o contraseña incorrectos. Por favor inténtelo de nuevo");
        });

    }


    return (
        <Modal show={isOpen} backdrop="static" onHide={handleClose}>
            <Modal.Header closeButton>
                Iniciar Sesión
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend d-flex align-items-stretch">
                            <span class="input-group-text" id="basic-addon1">
                                <FontAwesomeIcon icon={faUser} />
                            </span>
                        </div>
                        <input type="text" class="form-control" placeholder="Usuario" onChange={(ev) => setEmail(ev.target.value)} />
                    </div>

                    <div class="input-group mb-3">
                        <div class="input-group-prepend d-flex align-items-stretch">
                            <span class="input-group-text" id="basic-addon1">
                                <FontAwesomeIcon icon={faKey} />
                            </span>
                        </div>
                        <input type="password" class="form-control" placeholder="Contraseña" onChange={(ev) => setPassword(ev.target.value)} />
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" class="btn btn-light" onClick={handleClose}>Cancelar</button>
                <button type="button" class="btn btn-primary" onClick={submit}>Entrar</button>
            </Modal.Footer>
        </Modal>
    )
}

export default LogInModal;