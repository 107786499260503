import NewsletterBackground from '../images/NewsletterBackground.png';

const Newsletter = () => {
    return (
        <div className="newsletter d-flex flex-column justify-content-center align-items-center lh-1" style={{ background: `url(${NewsletterBackground})`, height: "525px" }}>
            
                <div className="fw-bold">
                    Newsletter
                </div>
                <div className="text-orange fs-5rem fw-900">
                    INFORMATE
                </div>
                <div className="fs-34">
                    DE NUESTRAS OFERTAS
                </div>
                <div>
                    <input className="my-3 text-white bg-gray border-0 py-3 px-5" placeholder='E-mail' />
                </div>
                <div className="fw-bold">
                    Registrate para recibir todas nuestras noticias.
                </div>
            
        </div>
    )
}

export default Newsletter;