// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faEdit, faBus, faPlane, faUserShield, faHotel } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import baseUrl from '../baseUrl';
import { toast } from 'react-toastify';

const NuestrosDestinosBox = (props) => {

    const deleteTrip = () => {
        Axios.post(baseUrl + "/deleteTrip", { tripId: props.trip.id })
        props.setTripsToShow(props.trips.filter((trip) => trip.id != props.trip.id));
        toast.success("¡Exito! El destino fué eliminado");
    }

    const editTrip = () =>{
        props.setEditTripData({
            nombre: props.trip.nombre,
            fecha: props.trip.fecha,
            exterior: props.trip.exterior,
            transporte: props.trip.transporte,
            descripcion: props.trip.descripcion,
            precio: props.trip.precio,
            imgpath: props.trip.imgpath,
            estadia: props.trip.estadia,
            comprar: "",
            archivado: props.trip.archivado,
            dias: props.trip.dias,
            noches: props.trip.noches,
            tableId: props.trip.tableId,
            regimen: props.trip.regimen,
            paxMax: props.trip.paxMax,
            slidepath: "",
            id: props.trip.id,            
        })
        props.setEditTripModalIsOpen(true);
    }

    return (
        <div className="col-xl-6 col-lg-12">
            <div className="box-our-trips">
                <div className="row gx-0">
                    <div className="col-md-6 box-our-trips-img" style={{ background: "url(" + props.trip.imgpath + ")" }} />
                    <div className="col-md-6">
                        <div className="box-our-trips-info">
                            <h4><a>{props.trip.nombre}</a></h4>
                            <div className="box-our-trips-info-meta">
                                <div className="box-our-trips-info-meta-days">
                                    <FontAwesomeIcon icon={faCalendarAlt} className="width-16px" />
                                    <span className="ms-2">{props.trip.fecha}</span>

                                </div>
                                <div className="box-our-trips-info-meta-days mt-2">
                                    <FontAwesomeIcon icon={faClock} className="width-16px" />
                                    <span className="ms-2">{props.trip.dias} dias - {props.trip.noches} noches</span>
                                </div>
                            </div>
                            <p>
                                {props.description}
                            </p>
                            <div className="box-our-trips-info-price">
                                Valor:  
                                {props.trip.exterior ? <span> U$S {props.trip.precio}</span> : <span> ${props.trip.precio}</span>}
                            </div>
                            <div className="box-our-trips-info-options justify-content-between">
                                    <div className="text-start float-left fs-7 d-flex align-items-center fw-600 flex-row">
                                        {props.trip.transporte == "avion" ? <span className="hoverLetterSpacing hoverTransition3 fs-5" ><FontAwesomeIcon className="me-2" icon={faPlane} /></span>
                                            :
                                            <span className="hoverLetterSpacing hoverTransition3 fs-5" ><FontAwesomeIcon className="me-2" icon={faBus} /></span>
                                        }

                                        <span className="hoverLetterSpacing hoverTransition3 fs-5" ><FontAwesomeIcon className="me-2" icon={faUserShield} /></span>

                                        {props.trip.estadia ? <span className="hoverLetterSpacing hoverTransition3 fs-5" ><FontAwesomeIcon className="me-2" icon={faHotel} /></span>
                                            :
                                            <span />
                                        }
                                    </div>


                                <a className="box-our-trips-info-options-link text-end">
                                    Detalles
                                    <span className="ms-1"><FontAwesomeIcon icon={faSignOutAlt} /></span>
                                </a>

                                {props.admin ?
                                    <div className="text-start float-left fs-7 d-flex align-items-center fw-600 mt-4">
                                        <span className="text-darkBlue cursor-pointer hoverLetterSpacing hoverTransition3"  onClick={editTrip} ><FontAwesomeIcon className="me-1" icon={faEdit} />Editar</span>
                                        <span className="ms-2 text-danger cursor-pointer hoverLetterSpacing hoverTransition3" onClick={deleteTrip}><FontAwesomeIcon className="me-1" icon={faTimes} />Eliminar</span>
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NuestrosDestinosBox;