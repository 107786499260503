import { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import baseUrl from '../../baseUrl';
import Axios from 'axios';
import { capitalize } from '../../functions';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RoomingDrag from '../../DnD/rooming-drag';
import { useDrop } from "react-dnd";
import { ItemTypes } from "./../../DnD/constants";
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';
import Moment from 'moment';
import ReactToPrint from "react-to-print";
import html2pdf from 'html2pdf.js';
import LogoHq from '../../images/LogoHq.png';

import EmptyState1 from '../../images/EmptyState1.png';

//EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faSave, faFilePdf, faPrint } from '@fortawesome/free-solid-svg-icons';

const Rooming = () => {

    const [trips, setTrips] = useState([]);
    const [bedrooms, setBedrooms] = useState([]);
    const [selectedTrip, setSelectedTrip] = useState();
    const [emptyBedrooms, setEmptyBedrooms] = useState([]);
    const [newRoomPassengers, setNewRoomPassengers] = useState([]);
    const [newBedroomType, setNewBedroomType] = useState();
    const [single, setSingle] = useState(0);
    const [dobleTwin, setDobleTwin] = useState(0);
    const [matrimonial, setMatrimonial] = useState(0);
    const [triple, setTriple] = useState(0);
    const [cuadruple, setCuadruple] = useState(0);
    const [quintuple, setQuintuple] = useState(0);

    let componentRef = useRef(null);


    useEffect(() => {
        getTrips();
    }, [])

    useEffect(() => {
        setBedroomsTotals();
    }, [bedrooms]);

    const getTrips = async () => {
        await Axios.get(baseUrl + "/getTripsData").then((response) => {
            setTrips(response.data);
        });
    }

    const setBedroomsTotals = () => {
        bedrooms.forEach(element => {
            setBedromTot(element[0]);
        });
    }
    const deleteBedroom = (b) => {
        Axios.post(baseUrl + "/deleteBedroom", { selectedBedroom: b, trip: selectedTrip.tableId }).then((response) => {
            handleGetBedrooms(selectedTrip.tableId);
            toast.success("¡Exito! Habitación eliminada");
        });
    }

    const deleteNewRoomPassenger = (data) => {
        setEmptyBedrooms([...emptyBedrooms, data]);
        setNewRoomPassengers(newRoomPassengers.filter((passenger) => passenger.dni !== data.dni));
    }

    const getBedrooms = (e) => {
        setSelectedTrip(trips[e.target.selectedIndex - 1]);
        setSingle(0);
        setDobleTwin(0);
        setMatrimonial(0);
        setTriple(0);
        setCuadruple(0);
        setQuintuple(0);
        handleGetBedrooms(e.target.value);
    }
    const handleGetBedrooms = async (e) => {
        setNewRoomPassengers([]);
        await Axios.get(baseUrl + "/getPassengersData", { params: { selectedTrip: e } }).then((response) => {
            //REDUCE TO GET GROUP BY - MAYBE YOU CAN EXTRACT IT TO A FUNCTION?
            const groupings = response.data.reduce((groupings, item) => {
                if (!groupings[item.habitacion]) {
                    groupings[item.habitacion] = [];
                    groupings[item.habitacion].push(item.habitacion);
                }
                groupings[item.habitacion].push(item);
                return groupings;
            }, {});

            const newBedrooms = Object.values(groupings).filter(item => item[0] !== "");
            setBedrooms(newBedrooms);
            const emptyBedrooms = groupings[""] ? Object.values(groupings[""]).filter(item => item.habitacion == "") : [];
            setEmptyBedrooms(emptyBedrooms);
        });
    }

    const saveBedroom = () => {
        Axios.post(baseUrl + "/updateBedroom", { passengers: newRoomPassengers, selectedTrip: selectedTrip.tableId, bedroomType: (newBedroomType + nanoid(4)) }).then((response) => {
            toast.success("¡Exito! Habitación agregada");
            setNewRoomPassengers([]);
            handleGetBedrooms(selectedTrip.tableId);
        });
    }

    const setBedroomType = (e) => {
        setNewBedroomType(e.target.value);
    }

    const setBedromTot = (b) => {
        const bCutted = b.slice(0, 2);

        switch (bCutted) {
            case "SI":
                setSingle(single => single+1);
                break;
            case "DT":
                setDobleTwin(dobleTwin => dobleTwin+1);
                break;
            case "MT":
                setMatrimonial(matrimonial=> matrimonial+1);
                break;
            case "TR":
                setTriple(triple=> triple+1);
                break;
            case "CU":
                setCuadruple(cuadruple=>cuadruple+1);
                break;
            case "QU":
                setQuintuple(quintuple=>quintuple+1);
                break;
        }
    }

    const getBedroomType = (b) => {
        const bCutted = b.slice(0, 2);

        switch (bCutted) {
            case "SI":
                return "Single";
            case "DT":
                //setDobleTwin(dobleTwin+1);
                return "DobleTwin";
            case "MT":
                //setMatrimonial(matrimonial+1);
                return "Matrimonial";
            case "TR":
                //setTriple(triple+1);
                return "Triple";
            case "CU":
                //setCuadruple(cuadruple+1);
                return "Cuadruple";
            case "QU":
                //setQuintuple(quintuple+1);
                return "Quintuple";
            default:
                return "Undefined";
        }
    }

    const getBedroomColor = (b) => {      
        const bCutted = b.slice(0, 2);

        switch (bCutted) {
            case "SI":
                return "#d9d200";
            case "DT":
                return "#4287f5";
            case "MT":
                return "#e042f5";
            case "TR":
                return "#06ba66";
            case "CU":
                return "#dbc200";
            case "QU":
                return "#000";
            default:
                return "#000";
        }
    }

    function DropBox() {
        const [{ canDrop, isOver }, drop] = useDrop(() => ({
            accept: ItemTypes.PASAJERO,
            drop: (monitor) => handleDrop(monitor),
            collect: (monitor) => ({
                canDrop: monitor.canDrop(),
                isOver: !!monitor.isOver(),
            })
        }), [emptyBedrooms]);

        const isActive = canDrop && isOver;

        return (
            <div
                className="box"
                ref={drop}
                style={{}}
            >
                <div>
                    {newRoomPassengers.length > 0 ?
                        newRoomPassengers.map((passenger) => (
                            <div className="d-flex flex-row align-items-center">
                                <input className="form-control my-2 disable-select" value={capitalize(passenger.pasajero)} disabled />
                                <FontAwesomeIcon icon={faTimes} className="text-danger fs-5 ms-2 cursor-pointer"
                                    onClick={() => deleteNewRoomPassenger(passenger)} />
                            </div>
                        ))
                        :
                        <div>
                            <div className="opacity-25 fs-6 my-3">Arrastre sus pasajeros aquí</div>
                            <FontAwesomeIcon icon={faPlus} className="fs-1 opacity-25 mb-3" />
                        </div>
                    }
                </div>

                <div className="mt-4 mb-2" onClick={saveBedroom}><button className="btn btn-secondary w-100">
                    GUARDAR
                    <FontAwesomeIcon icon={faSave} className="ms-2" />
                </button></div>


            </div>
        )
    }

    const handleDrop = (data) => {
        if (newRoomPassengers.length < 5) {
            setEmptyBedrooms(emptyBedrooms.filter((passenger) => passenger.id !== data.value.id));
            setNewRoomPassengers([...newRoomPassengers, data.value]);
        }
    };

    const reactToPrintContent = () => {
        return componentRef;
    };

    const setComponentRef = (ref) => {
        componentRef = ref;
    };

    const reactToPrintTrigger = () => {
        return <button type="button" class="btn btn-primary me-2" scale={0.6}><FontAwesomeIcon icon={faPrint} /></button>;
    };

    const downloadPdf = (fileName) => {
        const element = document.getElementById('roomingToPrint').innerHTML;
        const options = {
            image: { type: 'png' },
            filename: fileName + '.pdf',
        };
        html2pdf(element, options);
    }

    return (
        <div id="Rooming">
            <Breadcrumb title="Rooming" />
            <div className="container mb-5">
                <div className="row d-flex justify-content-center align-items-middle align-items-center w-100">
                    <div className="col-6">
                        <select className="form-control mb-2" defaultValue="0" name="trip" onChange={getBedrooms}>
                            <option value="0" disabled hidden>Seleccione un destino</option>
                            {trips.map((trip) => (
                                <option value={trip.tableId}>{trip.nombre} ({Moment(trip.fecha).format('DD/MM/YYYY')})</option>
                            ))}
                        </select>
                    </div>
                </div>
                {selectedTrip ?
                    <div>
                        <div className="d-flex justify-content-center mb-5" data-aos="fade-in">
                            <ReactToPrint
                                content={reactToPrintContent}
                                documentTitle="ROOMING"
                                trigger={reactToPrintTrigger}
                            />
                            <button type="button" className="btn btn-primary me-2" onClick={() => downloadPdf("ROOMING - " + selectedTrip.nombre + " " + Moment(selectedTrip.fecha).format('DD-MM-YYYY'))}>
                                <FontAwesomeIcon icon={faFilePdf} />
                            </button>
                        </div>

                        <div className="row" data-aos="fade-in">
                            <div className="fs-4 border-bottom pb-2 fw-bold w-100 mb-2 mt-4">
                                Pasajeros sin ubicar
                            </div>
                            <div id="Passengers-without-bedrooms" className="d-flex flex-row flex-wrap mt-4 mb-2">
                                <DndProvider backend={HTML5Backend}>
                                    {emptyBedrooms ? emptyBedrooms.map((passenger, index) => (
                                        <div>
                                            {passenger ?
                                                <div id="Passenger" className="mx-2">
                                                    <RoomingDrag key={passenger.id} passenger={passenger} />
                                                </div>
                                                :
                                                ""}
                                        </div>
                                    ))
                                        :
                                        ""}
                                </DndProvider>
                            </div>
                        </div>

                        <div className="row justify-content-center-onPrint m-1-onPrint" data-aos="fade-in" ref={setComponentRef} id="roomingToPrint">
                            <div className="row justify-content-center align-items-center col-12 flex-column my-2 d-none display-flex-onPrint">
                                <img src={LogoHq} style={{ width: "30%", margin:"0 auto" }} />
                                <div className="col bg-primary text-white fs-4 fw-600 text-center mt-3 text-uppercase">
                                    {selectedTrip ? selectedTrip.nombre : null}
                                </div>
                                <div className="col bg-darkblue text-white fs-6 fw-600 text-center text-uppercase">
                                    {selectedTrip ? Moment(selectedTrip.fecha).format('DD/MM/YYYY') : ""}
                                </div>
                            </div>
                            <div className="fs-4 border-bottom pb-2 fw-bold w-100 mb-2 mt-4">
                                Habitaciones
                            </div>
                            <div className="fs-6 fw-500 pb-2 w-100 mb-2 mt-2 d-flex flex-row">
                                <span className="me-3">Single: <span className="fw-bold text-danger">{single}</span></span>
                                <span className="me-3">Matrimonial: <span className="fw-bold text-danger">{matrimonial}</span></span>
                                <span className="me-3">DobleTwin: <span className="fw-bold text-danger">{dobleTwin}</span></span>
                                <span className="me-3">Triple: <span className="fw-bold text-danger">{triple}</span></span>
                                <span className="me-3">Cuadruple: <span className="fw-bold text-danger">{cuadruple}</span></span>
                                <span className="me-3">Quintuple: <span className="fw-bold text-danger">{quintuple}</span></span>
                            </div>
                            <div className="col-4 mt-5 bg-light hideOnPrint">
                                <div className="d-flex flex-column text-center">
                                    <div className="title fw-bold fs-5 my-2">NUEVA HABITACIÓN</div>
                                    <div className="title fw-bold fs-6 border-bottom pb-2 d-flex justify-content-center">
                                        <select className="form-control w-50" defaultValue="Undefined" onChange={setBedroomType}>
                                            <option value="Undefined" disabled hidden>Tipo de Habitación</option>
                                            <option value="SI-">Single</option>
                                            <option value="MT-">Matrimonial</option>
                                            <option value="DT-">DobleTwin</option>
                                            <option value="TR-">Triple</option>
                                            <option value="CU-">Cuadruple</option>
                                            <option value="QU-">Quintuple</option>
                                        </select>
                                    </div>
                                    <div className="pt-2 d-flex flex-column align-items-center">
                                        <DndProvider backend={HTML5Backend}>
                                            <DropBox />
                                        </DndProvider>
                                    </div>
                                </div>
                            </div>
                            {bedrooms.map((bedroom, index) => (
                                <div className="col-4 mt-5">
                                    <div className="d-flex flex-column text-center">
                                        {bedroom[0] != "" ?
                                            <div id={"Habitacion" + bedroom}>
                                                <div className="title fw-bold fs-5">HABITACIÓN {index + 1} <FontAwesomeIcon icon={faTimes} className="text-danger ms-1 cursor-pointer hideOnPrint" onClick={() => deleteBedroom(bedroom[0])} /></div>
                                                <div className="title fw-bold fs-6 border-bottom pb-2" style={{color: getBedroomColor(bedroom[1].habitacion) }}>{getBedroomType(bedroom[1].habitacion)}</div>
                                                {bedroom.slice(1).map((passengerInBedroom) => (
                                                    <div className="pt-2 d-flex flex-column">
                                                        <div>{capitalize(passengerInBedroom.pasajero)} <span className="fw-600 fs-8">{passengerInBedroom.salud != "No" ? `(${passengerInBedroom.salud})` : ""}</span></div>
                                                        <div className="fs-9">{passengerInBedroom.fechanac}</div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    :
                    <div className="d-flex flex-column justify-content-center align-items-center my-5" data-aos="fade-in">
                        <img src={EmptyState1} alt="Empty" />
                        <span className="fs-4 text-dark opacity-25 mt-2">No hay información disponible</span>
                        <span className="text-light text-dark opacity-25 fs-6">Debes seleccionar un destino</span>
                    </div>

                }
            </div>
        </div >

    )
}

export default Rooming;