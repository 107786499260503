import styled from "styled-components";

const Container = styled.svg`
  width: ${(props) => (props.height ? props.height : "16px")};
  height: ${(props) => (props.height ? props.height : "16px")};
  vertical-align: middle;
  transform-origin: center;
  animation: rotate 2s linear infinite;

  & circle {
    fill: none;
    stroke: ${(props) =>
      props.color ? props.color : props.theme.colors.COLOR_2};
    stroke-dasharray: 1, 200;
    stroke-width: ${(props) => (props.strokeWidth ? props.strokeWidth : "3px")};
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: stretch calc(2s * 0.75) ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes stretch {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dashoffset: -124px;
    }
  }
`;

export default function Spinner({
  color,
  height,
  strokeWidth,
  className,
  customAnimation,
  size,
  radius,
}) {
  return (
    <Container
      className={`spinner ${className}`}
      strokeWidth={strokeWidth}
      height={height}
      color={color}
      customAnimation={customAnimation}
      viewBox="25 25 50 50"
    >
      <circle cx={size ?? "50"} cy={size ?? "50"} r={radius ?? "20"} />
    </Container>
  );
}
