import './App.css';
import { BrowserRouter as Router, Routes, Switch, Route } from 'react-router-dom'
// MODULES
import TopHeader from './components/top-header';
import MainMenu from './components/main-menu';
import Footer from './components/footer';
import Home from './pages/home';
import Contacto from './pages/contacto';
import Usuarios from './pages/admin/usuarios';
import ClientsDatabase from './pages/admin/clients-database';
import TripsAdministration from './components/admin/trips-administration';
import Pax from './pages/admin/pax';
import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ToastContainer } from 'react-toastify';
import NuestrosDestinos from './pages/nuestros-destinos';
import Nosotros from './pages/quienes-somos';
import 'react-toastify/dist/ReactToastify.css';
import Grilla from './pages/admin/grilla';
import Rooming from './pages/admin/rooming';
import ReactWhatsApp from 'react-whatsapp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Excursiones from './pages/admin/excursiones';
import ConsultaRecibos from './pages/admin/consulta-recibos';


function App() {

  const [firebaseUser, setFirebaseUser] = useState(false)
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user)
        setFirebaseUser(user);
      else
        setFirebaseUser(null);
    });
  }, []);


  return (

    <Router>

      <header>

      </header>

      <div className="header">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <MainMenu firebaseUser={firebaseUser} />

      </div>

      <div className="main">

        <Routes>
          <Route path="/" exact={true} element={<Home />} />
          <Route path="/nuestros-destinos" exact={true} element={<NuestrosDestinos />} />
          <Route path="/nosotros" exact={true} element={<Nosotros />} />
          <Route path="/contacto" exact={true} element={<Contacto />} />
          <Route path="/usuarios" exact={true} element={<Usuarios />} />
          <Route path="/base-de-datos" exact={true} element={<ClientsDatabase />} />
          <Route path="/administrar-viajes" exact={true} element={<TripsAdministration />} />
          <Route path="/pax" exact={true} element={<Pax firebaseUser={firebaseUser} />} />
          <Route path="/grilla" exact={true} element={<Grilla />} />
          <Route path="/rooming" exact={true} element={<Rooming />} />
          <Route path="/excursiones" exact={true} element={<Excursiones />} />
          <Route path="/consulta-recibos" exact={true} element={<ConsultaRecibos />} />
        </Routes>

        <ReactWhatsApp className={firebaseUser != null ? "d-none" : "btn-wsp"} number="+542364262872" message='¡Hola! Te escribo desde el Sitio de Taiyo Viajes para realizarte una consulta'>
          <FontAwesomeIcon icon={faWhatsapp} />
            <span class="tooltiptext">¡Envianos tu consulta!</span>
        </ReactWhatsApp>

      </div>

      <div className="footer">
        <Footer />
      </div>

    </Router>
  );
}

export default App;
