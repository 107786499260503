
// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faEdit, faBus, faPlane, faUserShield, faHotel, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const OurTripsBox = (props) => {


    return (
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 p-0 overflow-hidden" data-aos="zoom-in-up">
            <div className="our-trips-box">
                <div className="our-trips-image mw-100 home-box-images d-flex align-items-end" style={{ background: `url(${props.imageLink})` }}>
                    <div className="info-div bg-white-90 w-100 d-flex flex-column py-4 px-3">
                        <span className="fw-600 text-orange fs-6 text-uppercase">{props.days} Dias - {props.noches} Noches</span>
                        <span className="fs-3 fw-800 text-uppercase">{props.title}</span>
                        <Link to="#" className="mt-3 fw-bold text-uppercase">
                            <FontAwesomeIcon icon={faCaretRight} className="text-orange me-2" />
                            <span className="text-gray">Ver Más</span>
                            </Link>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default OurTripsBox;