import Modal from "react-bootstrap/Modal";
import { useState, useEffect, useRef } from "react";
import Axios from "axios";

import Moment from "moment";
import ReactToPrint from "react-to-print";
import { capitalize } from "../../functions";
import baseUrl from "../../baseUrl";

// EXTRAS
import Recibo from "../Recibo/Recibo";
import { useAddNewRecibo } from "../../hooks/useAddNewRecibo";
import Button from "../Button/Button";

const NuevoRecibo = ({
  nuevoReciboIsOpen,
  setNuevoReciboIsOpen,
  firebaseUser,
  reciboIsParticular,
  reciboConsult,
  consultValues,
}) => {
  const reciboInitialValues = {
    fecha: Moment(new Date()).format("YYYY-MM-DD"),
    totalPesos: 0,
    totalDol: 0,
    tipo: "",
    medioDePago: "",
    comentario: "",
    destino: "",
    pasajero: "",
    priceText: "",
    paymentsId: "",
    agregadoPor: "",
  };

  const handleClose = () => {
    setNuevoReciboIsOpen(false);
    setReciboIsReady(false);
  };
  const [trips, setTrips] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [excursions, setExcursions] = useState([]);
  const [selectedPassenger, setSelectedPassenger] = useState();
  const [selectedTrip, setSelectedTrip] = useState();
  const [reciboValues, setReciboValues] = useState(reciboInitialValues);
  const [reciboIsReady, setReciboIsReady] = useState(false);

  const addNewRecibo = useAddNewRecibo();

  let componentRef = useRef(null);

  useEffect(() => {
    getTrips();
    setReciboValues(reciboInitialValues);
  }, []);

  useEffect(() => {
    setReciboValues(reciboInitialValues);
  }, [nuevoReciboIsOpen]);

  useEffect(() => {
    if (consultValues) setReciboValues(consultValues);
  }, [consultValues]);

  useEffect(() => {
    if (addNewRecibo.error) {
      setReciboIsReady(false);
    }
  }, [addNewRecibo.error]);

  useEffect(() => {
    if (addNewRecibo.success) {
      setReciboIsReady(true);
    }
  }, [addNewRecibo.success]);

  const getTrips = async () => {
    Axios.get(baseUrl + "/getTripsData").then((response) => {
      setTrips(response.data);
    });
  };

  const getPassengers = async (e) => {
    setSelectedTrip(e.target.value);

    setReciboValues({
      ...reciboValues,
      destino: e.target.selectedOptions[0].text,
      pasajero: "",
      agregadoPor: firebaseUser.displayName,
    });

    await Axios.get(baseUrl + "/getPassengersData", {
      params: { selectedTrip: e.target.value },
    }).then((response) => {
      setPassengers(response.data);
    });

    handleGetExcursions(trips[e.target.selectedIndex - 1].excursionsId);
  };

  const handleGetExcursions = async (e) => {
    await Axios.get(baseUrl + "/getExcursionsData", {
      params: { excId: e },
    }).then((response) => {
      setExcursions(response.data);
    });
  };

  const handlePassengerSelection = (e) => {
    setSelectedPassenger(passengers[e.target.selectedIndex - 1]);
    setReciboValues({
      ...reciboValues,
      pasajero: e.target.selectedOptions[0].text,
      paymentsId: passengers[e.target.selectedIndex - 1].paymentsId,
    });
  };

  const handleInputChange = (e, defaultValue) => {
    const { name, value } = e.target;
    setReciboValues({
      ...reciboValues,
      [name]: value === "" ? defaultValue : value,
    });
  };

  const handleInputExc = (e) => {
    const { name, value } = e.target;
    setReciboValues((previousValues) => ({
      ...previousValues,
      exc: { ...previousValues.exc, [name]: value },
    }));
  };

  const addRecibo = async (e) => {
    e.preventDefault();
    setReciboValues((previousValues) => ({
      ...previousValues,
      exc: JSON.stringify(previousValues.exc),
    }));

    addNewRecibo
      .post({
        reciboValues,
        selectedTrip,
        selectedPassenger,
        reciboIsParticular,
      })
      .then(() => setReciboIsReady(true));
  };

  const reactToPrintContent = () => {
    return componentRef;
  };

  const setComponentRef = (ref) => {
    componentRef = ref;
  };

  const reactToPrintTrigger = () => {
    return (
      <button type="button" class="btn btn-primary">
        Imprimir
      </button>
    );
  };

  return (
    <Modal
      show={nuevoReciboIsOpen}
      backdrop="static"
      onHide={handleClose}
      size="lg"
    >
      <Modal.Header closeButton>Nuevo Recibo</Modal.Header>
      <Modal.Body>
        <div id="recibo">
          {reciboIsReady || reciboConsult ? (
            //SHOW RECIBO DESIGN
            <Recibo
              reciboValues={reciboValues}
              setComponentRef={setComponentRef}
            />
          ) : reciboIsParticular ? (
            //RECIBO IS PARTICULAR
            <div className="d-flex flex-column">
              <form onSubmit={addRecibo} id="reciboForm">
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    name="destino"
                    placeholder="Destino"
                    onChange={(e) => handleInputChange(e, "")}
                  />
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    name="pasajero"
                    placeholder="Pasajero"
                    onChange={(e) => handleInputChange(e, "")}
                  />
                </div>

                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    name="tipo"
                    defaultValue={"default"}
                    onChange={handleInputChange}
                  >
                    <option value="default" disabled hidden>
                      Tipo de Pago
                    </option>
                    <option value="Paquete">Paquete</option>
                    <option value="Excursiones">Excursiones</option>
                    <option value="Traslado">Traslado</option>
                    <option value="Otro">Otro</option>
                  </select>
                </div>

                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    name="medioDePago"
                    defaultValue={"default"}
                    onChange={handleInputChange}
                  >
                    <option value="default" disabled hidden>
                      Medio de Pago
                    </option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Tarjeta de Débito">Tarjeta de Débito</option>
                    <option value="Tarjeta de Crédito">
                      Tarjeta de Crédito
                    </option>
                    <option value="Tarjeta Pre-Viaje">Tarjeta Pre-Viaje</option>
                    <option value="Transferencia Bancaria">
                      Transferencia Bancaria
                    </option>
                  </select>
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    name="priceText"
                    placeholder="Recibi(mos) la suma de.."
                    onChange={(e) => handleInputChange(e, "")}
                  />
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="number"
                    name="totalPesos"
                    placeholder="Total $"
                    onChange={(e) => handleInputChange(e, 0)}
                  />
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="number"
                    name="totalDol"
                    placeholder="Total U$S"
                    onChange={(e) => handleInputChange(e, 0)}
                  />
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    name="comentario"
                    placeholder="Comentario"
                    onChange={(e) => handleInputChange(e, "")}
                  />
                </div>
              </form>
            </div>
          ) : (
            //RECIBO NEW FROM DB
            <div className="d-flex flex-column">
              <form onSubmit={addRecibo} id="reciboForm">
                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    name="destino"
                    defaultValue={"default"}
                    onChange={getPassengers}
                  >
                    <option value="default" disabled hidden>
                      Seleccioná un Destino
                    </option>
                    {trips.map((trip) => (
                      <option value={trip.tableId}>
                        {trip.nombre} ({Moment(trip.fecha).format("DD/MM/YYYY")}
                        )
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    name="pasajero"
                    defaultValue={"default"}
                    onChange={handlePassengerSelection}
                  >
                    <option value="default" disabled hidden>
                      Pasajero
                    </option>
                    {passengers.map((passenger) => (
                      <option value={passenger.pasajero}>
                        {capitalize(passenger.pasajero)}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    name="tipo"
                    defaultValue={"default"}
                    onChange={handleInputChange}
                  >
                    <option value="default" disabled hidden>
                      Tipo de Pago
                    </option>
                    <option value="Paquete">Paquete</option>
                    <option value="Excursiones">Excursiones</option>
                    <option value="Traslado">Traslado</option>
                    <option value="Otro">Otro</option>
                  </select>
                </div>

                <div className="input-group mb-3">
                  <select
                    className="form-select"
                    name="medioDePago"
                    defaultValue={"default"}
                    onChange={handleInputChange}
                  >
                    <option value="default" disabled hidden>
                      Medio de Pago
                    </option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Tarjeta de Débito">Tarjeta de Débito</option>
                    <option value="Tarjeta de Crédito">
                      Tarjeta de Crédito
                    </option>
                    <option value="Tarjeta Pre-Viaje">Tarjeta Pre-Viaje</option>
                    <option value="Transferencia Bancaria">
                      Transferencia Bancaria
                    </option>
                    <option value="Cheque">Cheque</option>
                    <option value="Saldo a favor">Saldo a favor</option>
                  </select>
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    name="priceText"
                    placeholder="Recibi(mos) la suma de.."
                    onChange={(e) => handleInputChange(e, "")}
                  />
                </div>

                <div className="input-group mb-3">
                  {excursions.map((exc) => (
                    <input
                      className="form-control"
                      type="text"
                      name={exc.excursion}
                      placeholder={exc.excursion}
                      onChange={handleInputExc}
                    />
                  ))}
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="number"
                    name="totalPesos"
                    placeholder="Total $"
                    onChange={(e) => handleInputChange(e, 0)}
                  />
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="number"
                    name="totalDol"
                    placeholder="Total U$S"
                    onChange={(e) => handleInputChange(e, 0)}
                  />
                </div>

                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="text"
                    name="comentario"
                    placeholder="Comentario"
                    onChange={(e) => handleInputChange(e, "")}
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {reciboIsReady || reciboConsult ? (
          <div>
            <ReactToPrint
              content={reactToPrintContent}
              documentTitle="Recibo"
              trigger={reactToPrintTrigger}
            />
          </div>
        ) : (
          <div>
            <Button onClick={handleClose} text="Cancelar" />

            <Button
              type="submit"
              form="reciboForm"
              text="Generar"
              variant="PRIMARY"
              isLoading={addNewRecibo.isLoading}
              disabled={
                reciboValues.destino === "" ||
                reciboValues.pasajero === "" ||
                reciboValues.medioDePago === "" ||
                reciboValues.tipo === "" ||
                (reciboValues.totalPesos === 0 && reciboValues.totalDol === 0)
              }
            />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default NuevoRecibo;
