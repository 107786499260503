import Axios from "axios";
import Breadcrumb from "../../components/breadcrumb";
import baseUrl from "../../baseUrl";
import { FloatingLabel } from "react-bootstrap";
import { Form } from "react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { capitalize } from "../../functions";
import ReciboModal from "../../components/admin/recibo-modal";

// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint} from '@fortawesome/free-solid-svg-icons';

const ConsultaRecibos = () => {

    const initialInputValues = {
        desde: "2017-05-09",
        hasta: moment().format("YYYY-MM-DD"),
        pasajero: "",
        destino: "",
    }

    const [recibos, setRecibos] = useState([]);
    const [inputValues, setInputValues] = useState(initialInputValues);
    const [recibosFiltered, setRecibosFiltered] = useState([]);
    const [reciboToPrint, setReciboToPrint] = useState();
    const [reciboModalIsOpen, setReciboModalIsOpen] = useState(false);

    useEffect(() => {
        getRecibos();
    }, [])

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    }

    const getRecibos = () => {
        Axios.get(baseUrl + "/getRecibosData").then((response) => {
            setRecibos(response.data);
        })
    }

    const getCurrentDate = () => {
        return moment().format("YYYY-MM-DD");
    }

    const searchRecibos = () => {
        setRecibosFiltered(recibos.filter((recibo) => recibo.pasajero.toLowerCase().includes(inputValues.pasajero.toLowerCase()) && recibo.destino.toLowerCase().includes(inputValues.destino.toLowerCase()) && recibo.fecha >= inputValues.desde && recibo.fecha <= inputValues.hasta));
    }

    const openReciboModal = (reciboId) => {
        setReciboToPrint( (recibos.filter ( (recibo) => recibo.id === reciboId))[0] );
        setReciboModalIsOpen(true);         
    }

    const options = {
        responsive: "standard",
        rowsPerPage: 120,
        searchPlaceholder: "Buscar...",
        elevation: 0,
        pagination: false,
        selectableRows: 'none',
        textLabels: {
            body: {
                noMatch: "No hay información disponible.",
                toolTip: "Ordenar",
                columnHeaderTooltip: column => `Ordenar por ${column.label}`
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar CSV",
                print: "Imprimir",
                viewColumns: "Ocultar Columnas",
                filterTable: "Filtrar Tabla",
            },
            filter: {
                all: "Todos",
                title: "Filtros",
                reset: "Reiniciar",
            },
            viewColumns: {
                title: "Ver Columnas",
                titleAria: "Ver/Ocultar Columnas de la Tabla",
            },
        },
        expandableRows: false,
    };
    const columns = [
        {
            name: "id",
            label: "Nº",
        },
        {
            name: "pasajero",
            label: "Pasajero",
            options: {
                customBodyRender: value => <span>{capitalize(value)}</span>,
            }
        },
        {
            name: "fecha",
            label: "Fecha",
            options: {
                customBodyRender: value => <span>{moment(value).format('DD/MM/YYYY')}</span>
            }
        },
        {
            name: "tipo",
            label: "Tipo",
        },
        {
            name: "destino",
            label: "Destino",
        },
        {
            name: "medioDePago",
            label: "Medio de Pago",
        },
        {
            name: "totalPesos",
            label: "Total $",
        },
        {
            name: "totalDol",
            label: "Total U$S",
        },
        {
            name: "comentario",
            label: "Comentario",
            options: {
                customBodyRender: value => <span>{capitalize(value.toLowerCase())}</span>,
            }
        },
        {
            name: "id",
            label: <FontAwesomeIcon icon={faPrint} />,
            options: {
                customBodyRender: value => <span className="text-primary cursor-pointer" id={value} onClick={() => openReciboModal(value)}><FontAwesomeIcon icon={faPrint} /></span>,
            }
        },
    ]

    return (
        <div id="ConsultaRecibos">
            <Breadcrumb title="Consulta de Recibos" />

            <div className="container mb-5 pb-4">
                <div className="d-flex flex-row mb-3">
                    <div className="form-floating me-3 w-100">
                        <FloatingLabel label="Desde..." controlId="floatingInput">
                            <Form.Control name="desde" defaultValue="2017-05-09" type="date" max={getCurrentDate()} onChange={handleInput} />
                        </FloatingLabel>
                    </div>

                    <div className="form-floating w-100">
                        <FloatingLabel label="Hasta..." controlId="floatingInput">
                            <Form.Control type="date" name="hasta" defaultValue={getCurrentDate()} max={getCurrentDate()} onChange={handleInput} />
                        </FloatingLabel>
                    </div>
                </div>

                <div className="d-flex flex-row mb-3">
                    <div className="form-floating me-3 w-100">
                        <FloatingLabel label="Ingrese Pasajero" controlId="floatingInput">
                            <Form.Control type="text" name="pasajero" placeholder="Ingrese Pasajero" onChange={handleInput} />
                        </FloatingLabel>
                    </div>

                    <div className="form-floating w-100">
                        <FloatingLabel label="Ingrese Destino" controlId="floatingInput">
                            <Form.Control type="text" name="destino" placeholder="Ingrese Destino" onChange={handleInput} />
                        </FloatingLabel>
                    </div>
                </div>

                <div className="button-search d-flex">
                    <button className="btn btn-primary w-100" onClick={searchRecibos}>Buscar</button>
                </div>

                <div id="recibosTable">
                    <ThemeProvider theme={createTheme()}>
                        <MUIDataTable
                            data={recibosFiltered}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                </div>

            </div>

            <ReciboModal reciboConsult="true" nuevoReciboIsOpen={reciboModalIsOpen} setNuevoReciboIsOpen={setReciboModalIsOpen} consultValues={reciboToPrint} />

        </div>
    )
}

export default ConsultaRecibos; 