import Modal from 'react-bootstrap/Modal';
import { useEffect, useState, useRef } from 'react';
import { nanoid } from 'nanoid';
import Axios from 'axios';
import baseUrl from "../../baseUrl";
import { toast } from 'react-toastify';

// EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const EditTripModal = (props) => {

    const handleClose = () => {
        props.setEditTripModalIsOpen(false);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        props.setEditTripData({ ...props.editTripData, [name]: value });
        console.log(props.editTripData);
    }

    const handleSubmit = () => {
        Axios.post(baseUrl+"/updateTrip", {tripData: props.editTripData});
        toast.success("¡Exito! Destino editado");
        props.setNewTripModalIsOpen(false);
    }


    return (
        <Modal show={props.editTripModalIsOpen} backdrop="static" onHide={handleClose} dialogClassName="w-75 mw-inherit">
            <Modal.Header closeButton>
                EDITAR DESTINO
            </Modal.Header>
            <Modal.Body>
                <div className="NewTripModal">
                    <div className="input-group mb-3">
                        <input className="form-control" type="text" name="nombre" placeholder="Destino" defaultValue={props.editTripData.nombre} onChange={handleInputChange}  />
                    </div>

                    <div className="input-group mb-3">
                        <input type="date" className="form-control me-3" name="fecha" placeholder="Fecha" defaultValue={props.editTripData.fecha} onChange={handleInputChange} />
                        <input type="number" className="form-control" name="paxMax" defaultValue={props.editTripData.paxMax} placeholder="Cantidad de Pax" onChange={handleInputChange} />
                    </div>

                    <div className="input-group mb-3">
                        <input type="number" className="form-control me-3" name="dias" defaultValue={props.editTripData.dias} placeholder="Dias" onChange={handleInputChange} />
                        <input type="number" className="form-control" name="noches" defaultValue={props.editTripData.noches} placeholder="Noches" onChange={handleInputChange} />
                    </div>

                    <div className="input-group mb-3">
                        <select type="text" className="form-control me-3" name="transporte" placeholder="" defaultValue={props.editTripData.transporte} onChange={handleInputChange} >
                            <option value="bus">Bus</option>
                            <option value="avion">Avion</option>
                        </select>

                        <select className="form-control me-3" name="exterior" defaultValue={props.editTripData.exterior} onChange={handleInputChange}>
                            <option value={1}>Si</option>
                            <option value={0}>No</option>
                        </select>

                        <select className="form-control" name="estadia" defaultValue={props.editTripData.estadia} onChange={handleInputChange}>
                            <option value={1}>Si</option>
                            <option value={0}>No</option>
                        </select>
                    </div>

                    <div className="input-group mb-3">
                        <select className="form-control" name="regimen" defaultValue={props.editTripData.regimen} onChange={handleInputChange} >
                            <option value="Desayuno">Desayuno</option>
                            <option value="Media Pension">Media Pension</option>
                            <option value="Pension Completa">Pension Completa</option>
                        </select>
                    </div>

                    <div className="input-group mb-3">
                        <input type="number" className="form-control" name="precio" placeholder="Precio" defaultValue={props.editTripData.precio} onChange={handleInputChange} />
                    </div>

                    <div className="input-group mb-3">
                        <textarea type="text" className="form-control" name="descripcion" placeholder="Descripcion" defaultValue={props.editTripData.descripcion} onChange={handleInputChange} />
                    </div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-light" onClick={handleClose}>Cerrar</button>
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>Guardar</button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditTripModal;
