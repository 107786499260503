import Breadcrumb from '../breadcrumb';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import baseUrl from '../../baseUrl';
import TripsBox from "../nuestros-destinos-box";
import NewTripModal from './new-trip-modal';
import EditTripModal from './edit-trip-modal';

//EXTRAS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const TripsAdministration = () => {

    const [tripsToShow, setTripsToShow] = useState([]);
    const [newTripModalIsOpen, setNewTripModalIsOpen] = useState(false);
    const [editTripModalIsOpen, setEditTripModalIsOpen] = useState(false);
    const [editTripData, setEditTripData] = useState(false);

    useEffect(() => {
        getTrips();
    }, []);

    const getTrips = async () => {
        Axios.get(baseUrl + "/getTripsData").then((response) => {
            setTripsToShow(response.data);
        });
    }

    const handleOpenNewTripModal = () => {
        setNewTripModalIsOpen(true);
    }

    return (
        <div id="NewTrip">
            <Breadcrumb title="Viajes" />
            <div className="container">
                <div className="row">
                    <div>
                        <button type="button" className="btn btn-success w-100 mb-4" onClick={handleOpenNewTripModal}><FontAwesomeIcon icon={faPlus} className="me-3" />Nuevo Destino</button>
                    </div>
                    {tripsToShow.map((trip) =>
                        <TripsBox
                        trip={trip}
                            setTripsToShow={setTripsToShow} trips={tripsToShow} transporte={trip.transporte} admin={true} setEditTripModalIsOpen={setEditTripModalIsOpen} setEditTripData={setEditTripData} />
                    )}
                </div>
            </div>

            <NewTripModal getTripsData={getTrips} newTripModalIsOpen={newTripModalIsOpen} setNewTripModalIsOpen={setNewTripModalIsOpen} tripsToShow={tripsToShow} setTripsToShow={setTripsToShow}/>

            <EditTripModal editTripModalIsOpen={editTripModalIsOpen} setEditTripModalIsOpen={setEditTripModalIsOpen}
            editTripData={editTripData} setEditTripData={setEditTripData} tripsToShow={tripsToShow} setTripsToShow={setTripsToShow}
            />
        </div>
    )
}

export default TripsAdministration;