import { useDrag } from 'react-dnd';
import { ItemTypes } from './constants';
import { capitalize } from '../functions';


const RoomingDrag = ({id, passenger}) => {

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: ItemTypes.PASAJERO,
    item: { value: passenger, itemId: id },
    options: {dropEffect: "move"},
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging()
    })
  }));

  return (
      <div className="col mb-3">
        <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
          <input className="form-control text-center disable-select" role="Handle" value={capitalize(passenger.pasajero)} id={passenger.id} disabled ref={drag} />
        </div>
      </div>
  );

}

export default RoomingDrag;