import Axios from "axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import baseUrl from "../baseUrl";

export const useAddNewRecibo = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  console.log(success);

  const post = useCallback(
    async ({
      reciboValues,
      selectedTrip,
      selectedPassenger,
      reciboIsParticular,
    }) => {
      setIsLoading(true);

      try {
        await Axios.post(baseUrl + "/addNewRecibo", {
          recibo: reciboValues,
          selectedTrip: selectedTrip,
          selectedPassenger: selectedPassenger,
          isParticular: reciboIsParticular,
        });

        toast.success("¡Exito! Recibo almacenado");

        setSuccess(true);
      } catch (e) {
        setError(e);
        toast.error("Error! Sucedio un problema");
        setSuccess(false);
      }
      setIsLoading(false);
    },
    []
  );

  return { success, error, isLoading, post };
};
