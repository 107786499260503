import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from './../images/Logo.png';
import Navbar from './navbar';
import { getAuth } from 'firebase/auth';


// EXTRAS
import { IoAirplaneOutline, IoChatboxEllipsesOutline, IoHomeOutline, IoPeopleOutline, IoLogOutOutline, IoLogInOutline } from "react-icons/io5";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPhone } from '@fortawesome/free-solid-svg-icons';
import LogInModal from './log-in-modal';

const MainMenu = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const [logIsOpen, setLogIsOpen] = useState(false);

  const auth = getAuth();

  const handleShowLogInModal = () => setLogIsOpen(true);

  const handleLogOut = () => { auth.signOut(); }

  return (
    <div id="PrimaryBar" className="row bg-white text-dark px-5">
      <div className={isOpen ? 'mask position-fixed opacity-100' : 'opacity-0'}></div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white container-small">
        <Link className="navbar-brand" to="#" data-aos="fade-right">
          <img src={logo} alt="Logo" className="" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse h-100" id="navbarSupportedContent" data-aos="fade-in">
          <ul className="mainMenu navbar-nav m-auto mb-2 mb-lg-0 fw-600 h-100">

            <NavLink activeClassName="active" className="navlink-item" to="/">
              <li className="nav-item d-flex align-content-center py-3 px-4">
                <div className="nav-link mx-3 d-flex flex-column justify-content-center align-items-center">
                  <span className="fs-5 mb-1"><IoHomeOutline /></span>
                  <span>INICIO</span>
                </div>
              </li>
            </NavLink>

            <NavLink activeClassName="active" className="navlink-item" to="/nuestros-destinos">
              <li className="nav-item d-flex align-content-center py-3 px-4">
                <div className="nav-link mx-3 d-flex flex-column justify-content-center align-items-center">
                  <span className="fs-5 mb-1"><IoAirplaneOutline /></span>
                  <span>DESTINOS</span>
                </div>
              </li>
            </NavLink>

            <NavLink activeClassName="active" className="navlink-item" to="/nosotros">
              <li className="nav-item d-flex align-content-center py-3 px-4">
                <div className="nav-link mx-3 d-flex flex-column justify-content-center align-items-center">
                  <span className="fs-5 mb-1"><IoPeopleOutline /></span>
                  <span>NOSOTROS</span>
                </div>
              </li>
            </NavLink>

            <NavLink activeClassName="active" className="navlink-item" to="/contacto">
              <li className="nav-item d-flex align-content-center py-3 px-4">
                <div className="nav-link mx-3 d-flex flex-column justify-content-center align-items-center">
                  <span className="fs-5 mb-1"><IoChatboxEllipsesOutline /></span>
                  <span>CONTACTO</span>
                </div>
              </li>
            </NavLink>

          </ul>

          <div className="d-flex h-100 sm-w100">
            <div className="d-flex align-items-center" id="header-right" data-aos="fade-left">

              {props.firebaseUser !== null ?
                <div className="h-100 d-flex align-items-center sm-w100">
                  <div className="sm-w50 sm-justify-content-center sm-p8 btn-darkGray text-white h-100 cursor-pointer px-4 fs-3 d-flex align-items-center" onClick={handleLogOut}>
                    <IoLogOutOutline />
                  </div>
                  <div id="sidebar-menu" className="sm-w50 sm-justify-content-center sm-p8 btn-lightGray text-gray px-3 fs-5 h-100 d-flex align-items-center" onClick={() => setIsOpen(!isOpen)}>
                    <FontAwesomeIcon icon={faBars} />
                  </div>
                </div>
                :
                <div className="btn-gray h-100 cursor-pointer px-4 fs-3 d-flex align-items-center sm-w100 sm-justify-content-center" onClick={handleShowLogInModal}>
                  <IoLogInOutline />
                </div>

              }

            </div>

          </div>
        </div>
      </nav>

      <Navbar isOpen={isOpen} setIsOpen={setIsOpen} firebaseUser={props.firebaseUser} />

      <LogInModal isOpen={logIsOpen} setIsOpen={setLogIsOpen} />

    </div>
  )
}

export default MainMenu;