import { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";

const DeleteUser = () => {

    const [usersArray, setUsersArray] = useState([]);
    const [selectedDocId, setSelectedDocId] = useState("");

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        const querySnapshot = await getDocs(collection(db, "users"));
        const docs = [];
        querySnapshot.forEach((doc) => {
            docs.push({ ...doc.data(), id: doc.id, nombre: doc.data().nombre, apellido: doc.data().apellido });
        });
        setUsersArray(docs);
    }

    const deleteUser = async () => {
        await deleteDoc(doc(db, "users", selectedDocId));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        deleteUser();
    };

    const handleSelectChange = (e) => {
        setSelectedDocId(e.target.value);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="card card-body" id="newUserForm">
                <h4 className="pb-4 mb-4 border-bottom">ELIMINAR USUARIO</h4>
                <select class="form-select mb-3" placeholder="Seleccioná un Usuario" onChange={handleSelectChange}>
                    {usersArray.map((user) => (
                        <option value={user.id}>{user.nombre} {user.apellido}</option>
                    ))}
                </select>


                <div className="d-flex justify-content-end">
                    <button className="btn btn-danger" type="submit">ELIMINAR</button>
                </div>
            </form>

        </div>
    )
}

export default DeleteUser;