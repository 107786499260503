import { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../components/breadcrumb';
import baseUrl from '../../baseUrl';
import Axios from 'axios';
import Moment from 'moment';
import html2pdf from 'html2pdf.js';
import ReactToPrint from "react-to-print";
import { capitalize } from '../../functions';

import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";

const Excursiones = () => {

    const columnsInitialValue = [
        {
            name: "id",
            label: "Nº",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => <span>{tableMeta.rowIndex + 1}</span>,
            }
        },
        {
            name: "pasajero",
            label: "Pasajero",
            options: {
                customBodyRender: value => <span>{capitalize(value)}</span>,
            }
        },
    ];

    const [trips, setTrips] = useState([]);
    const [selectedTrip, setSelectedTrip] = useState();
    const [passengers, setPassengers] = useState([]);
    const [excursions, setExcursions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [recibos, setRecibos] = useState([]);

    useEffect(() => {
        getTrips();
        setColumns(columnsInitialValue);
    }, []);

    const getTrips = async () => {
        await Axios.get(baseUrl + "/getTripsData").then((response) => {
            setTrips(response.data);
        });
    }

    const getExcursions = (e) => {
        setSelectedTrip(trips[e.target.selectedIndex - 1]);
        handleGetRecibos(e);        
    }

    const handleGetPassengers = async (e, r) => {
        await Axios.get(baseUrl + "/getPassengersData", { params: { selectedTrip: e } }).then((response) => {
            setPassengers(response.data);
            response.data.map((passenger, index) => {
                const passengerRecibos = r.filter( (recibo) => recibo.paymentsId == passenger.paymentsId); 
                passengerRecibos.length > 0 && passengerRecibos.map( (recibo,i) => {
                    //const newItem = {...passenger, ChaltenyCalafate_b0382:"1500"}
                    //setPassengers(({...response.data, [index]:newItem }));
                    console.log(index);
                    console.log(({...response.data, [index]:{...passenger, ["Chalten y Calafate_b0382"]:"1500"} }));
                    console.log(recibo.exc);
                })
                
            })
        })        
    }

    const handleGetExcursions = async (e, recibos) => {
        await Axios.get(baseUrl + "/getExcursionsData", { params: { excId: trips[e.target.selectedIndex - 1].tableId } }).then((response) => {
            setExcursions(response.data);
            const columnsToAdd = [];
            response.data.map((exc) => (
                columnsToAdd.push({ name: exc.excursionId, label: exc.excursionId + ` ($${exc.valor})` })
            ))
            setColumns([...columns, ...columnsToAdd]);
        }).then( (r) => {
            handleGetPassengers(e.target.value, recibos);
        })
    }

    const handleGetRecibos = (e) => {
        Axios.get(baseUrl + "/getRecibosData").then((response) => {
            setRecibos(response.data);
            handleGetExcursions(e, response.data)
        })
    }

    return (
        <div id="Excursiones">
            <Breadcrumb title="Excursiones" />
            <div className="container mb-5">
                <div className="row d-flex justify-content-center align-items-middle align-items-center w-100">
                    <div className="col-6">
                        <select className="form-control mb-2" defaultValue="0" name="trip" onChange={getExcursions}>
                            <option value="0" disabled hidden>Seleccione un destino</option>
                            {trips.map((trip) => (
                                <option value={trip.tableId}>{trip.nombre} ({Moment(trip.fecha).format('DD/MM/YYYY')})</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div id="tableWithData" data-aos="fade-in">
                    <ThemeProvider theme={createTheme()}>
                        <MUIDataTable
                            data={passengers}
                            columns={columns}
                        />
                    </ThemeProvider>
                </div>

            </div>
        </div>
    )
}

export default Excursiones;